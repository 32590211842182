import { createContext, useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import {
	useAllAgenciasQuery,
} from '../generated/graphql';

const getInitialAgencia = () => {
	try {
		const initialAgencia = JSON.parse(localStorage.getItem('agencia'))
		if (initialAgencia === null) {
			return [1]
		}
		return initialAgencia
	} catch (error) {
		return [1];
	}
}

interface AgenciaContextI {
    agencia: any;
		umn: any;
		dataAgencia: any;
    setAgencia: any;
		setDataAgencia: any;
		setUmn: any;
    allAgencies: any;
  }

export const AgenciaContext = createContext<AgenciaContextI>({
    agencia: getInitialAgencia(),
		dataAgencia: [],
		umn: [],
    setAgencia: () => {},
		setDataAgencia: () => {},
		setUmn: () => {},
    allAgencies: []
});

export const AgenciaProvider = ({children}) => {
	const initialAgencia = getInitialAgencia()
	const { data, loading:loadingAgencia } = useAllAgenciasQuery()
	const [agencia, setAgencia] = useState(initialAgencia);
	const [dataAgencia, setDataAgencia] = useState([]);
	const [umn, setUmn] = useState([]);

  useEffect(() => {
		if(data) setDataAgencia(data?.allAgencies)
	}, [loadingAgencia])

	useEffect(() => {
		console.log('set agencia from query: ',dataAgencia)
	}, [dataAgencia])

	useEffect(() => {
		console.log('agencia: ',agencia)
	}, [agencia])

	useEffect(() => {

	}, [data])

  const allAgencies = useMemo(() => data?.allAgencies?.map((agencie) => agencie?.numero), [data])

  return (
    <AgenciaContext.Provider
      value={{
        agencia,
				dataAgencia,
				umn,
        setAgencia,
				setDataAgencia,
				setUmn,
        allAgencies
      }}
    >
      {children}
    </AgenciaContext.Provider>
  );
};

AgenciaProvider.propTypes = {
  children: PropTypes.node.isRequired
};
