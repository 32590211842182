import type { FC } from 'react';
import { useContext, useEffect } from 'react'
import { DateContext } from 'src/contexts/DateContext';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import dayjs from "dayjs"
import Export from '../../export/Exports';
import Scrollbar from '../../Scrollbar';
import formatNumbers from '../../../utils/formatNumbers'

interface TableTotalGeneralAgrupadosProps {
  items: any[];
  sorteo: any;
}

const TableTotalGeneralAgrupados: FC<TableTotalGeneralAgrupadosProps> = (props) => {
    const totales = {
        cantidad: 0,
        comisionA: 0,
        comisionC: 0,
        monto: 0,
        premio: 0,
        neto: 0,
        totalPremiados: 0
    }
    const { items, sorteo, ...other } = props;

    const {actualDate} = useContext(DateContext)

    useEffect(() => {}, [sorteo, items])

    const headers = ["Fecha", "Cantidad", "Premiados", "Total Bruto", "Premio", "%", "Comision A", "Comision C", "Neto"];
    let data = items.map((item)=> [
      dayjs(item.fecha).format("DD/MM/YYYY"),
      formatNumbers(parseInt(item.cantidad, 10)),
      formatNumbers(parseInt(item.cantidad_premiados, 10)),
      formatNumbers(parseInt(item.monto, 10)),
      `${item.premio === null ? '-' : formatNumbers(parseInt(item.premio, 10))}`,
      `${ ((parseInt(item.premio, 10) / parseInt(item.monto, 10)) * 100).toFixed(2)}%`,
      `${formatNumbers(parseInt(item.comisionA, 10))}`,
      `${formatNumbers(parseInt(item.comisionC, 10))}`,
      `${ formatNumbers(parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10)) }`
    ]);
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      totales.cantidad += parseInt(item.cantidad, 10)
      totales.comisionA += parseInt(item.comisionA, 10)
      totales.comisionC += parseInt(item.comisionC, 10)
      totales.monto += parseInt(item.monto, 10)
      totales.premio += parseInt(item.premio, 10)
      totales.neto += (parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10))
      totales.totalPremiados += parseInt(item.cantidad_premiados, 10)
    })
    data = [
      ...data,
      [
        "Total: ",
        formatNumbers(totales.cantidad),
        formatNumbers(totales.totalPremiados),
        formatNumbers(totales.monto),
        `${totales.premio === 0 ? '-' : formatNumbers(totales.premio)}`,
        `${ ((totales.premio / totales.monto) * 100).toFixed(2)}%`,
        `${formatNumbers(totales.comisionA)}`,
        `${formatNumbers(totales.comisionC)}`,
        `${ formatNumbers(totales.neto)}`
      ]
    ]

    const pdfData = {
      fontSize: 10,
      title: "Agrupados por fecha",
      headers,
      data,
      downloadTitle: `agrupados-${dayjs().format('DD-MM-YYYY')}`,
    }

    return (
    <>
        <Card {...other}>
            <CardHeader
                action={<Export pdf
                pdfData={pdfData} />}
                title="Agrupados por fecha"
            />
            <Typography
                color="textSecondary"
                variant="h6"
                sx = {{marginLeft: 2}}
            >
                Desde {dayjs(actualDate.startDate).format("DD/MM/YYYY")} hasta {dayjs(actualDate.endDate).format("DD/MM/YYYY")}
            </Typography>

            <Typography
                color="textSecondary"
                variant="h6"
                sx = {{marginLeft: 2}}
            >
							{/* eslint-disable-next-line no-nested-ternary */}
                {sorteo === '2' ? "Sorteo Matutino" : sorteo === '4' ? " Sorteo Vespertino" : sorteo === '5' ? "Sorteo Nocturno" : sorteo === '20' ? "Sorteo Purete" : "Todos los sorteos"}
            </Typography>
						<Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Fecha
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Cantidad
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premiados
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Total Bruto
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premio
																	</Typography>
                                </TableCell>
																<TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    %
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comision Agencia
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comision Corredor
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Neto
																	</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => <TableRow
                                    key={item.id}
                                    >
                                        <TableCell>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                {dayjs(item.fecha).format("DD/MM/YYYY")}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                {formatNumbers(parseInt(item.cantidad, 10))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                {formatNumbers(parseInt(item.cantidad_premiados, 10))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                {formatNumbers(parseInt(item.monto, 10))}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                { item.premio === null ? '-' : formatNumbers(parseInt(item.premio, 10)) }
                                            </Typography>
                                        </TableCell>
																				<TableCell align="right">
                                        <Typography
                                            sx={{fontSize: parseInt(item.premio, 10) > parseInt(item.monto, 10) * 0.54? "20px": "16px"}}
                                            color={parseInt(item.premio, 10) > parseInt(item.monto, 10) * 0.54? "red": "textPrimary"}
                                            variant="subtitle1"
                                        >
                                            {((parseInt(item.premio, 10) / parseInt(item.monto, 10)) * 100).toFixed(2)}%
                                        </Typography>
                                    </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                { formatNumbers(parseInt(item.comisionA, 10)) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                { formatNumbers(parseInt(item.comisionC, 10)) }
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                        >
                                            <Typography

                                                variant="subtitle1"
                                            >
                                                { formatNumbers(parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10)) }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                            )}
                            <TableRow
                                sx={totales.neto < 0? {backgroundColor: 'error.main'}: {backgroundColor: 'inherit'}}
                            >
                                <TableCell>
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"black"}
                                        variant="h6"
                                    >
                                        Total
                                    </Typography>
                                </TableCell>

                                <TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.cantidad)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.totalPremiados)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.monto)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.premio)}
                                    </Typography>
                                </TableCell>
																<TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        { totales.premio > totales.monto ? ((-totales.premio / totales.monto) * 100).toFixed(2): ((totales.premio / totales.monto) * 100).toFixed(2)}%
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.comisionA)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.comisionC)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color={totales.neto < 0 ? "white" :"textPrimary"}
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.neto)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
        </Card>
    </>)
};

TableTotalGeneralAgrupados.propTypes = {
	items: PropTypes.array.isRequired,
	sorteo: PropTypes.any
};

export default TableTotalGeneralAgrupados;
