import type { FC } from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import {
	Box,
	Button,
	Paper,
	TextField,
	Typography,
	FormControl,
	FormHelperText,
	NativeSelect,
	Dialog,
	DialogActions,
	DialogTitle
} from '@material-ui/core';
// import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs'
import { useGenerarCobroMutation } from '../../../generated/graphql'
import { DialogComplete } from './DialogComplete'
import useAuth from '../../../hooks/useAuth'
import formatNumbers from '../../../utils/formatNumbers'
import Loading from '../../loading/Loading'

interface modalGenerar {
	handleCloseGenerar: any;
	data: any;
	refetchData: any;
}

const Generar: FC<modalGenerar> = (props) => {

	const auth = useAuth();

	const { handleCloseGenerar, data, refetchData } = props;

	console.log('data de entregas', data)

	const [corredor, setCorredor] = useState<{ id: string | number; nombre: string }>({
		id: '',
		nombre: '',
	});
	const [monto, setMonto] = useState(undefined);
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [ciCobrador, setCICobrador] = useState(0)
	const [cobrador, setCobrador] = useState(0);
	const [cobroComplete, setCobroComplete] = useState(false);
	const [title, setTitle] = useState('')
	const [cobradorDataNumber, setCobradorDataNumber] = useState(data.filter(c => c.numero.toString() === cobrador.toString()))
	const handleCorredorChange = (e) => {
		setCorredor({
			id: e.target.value,
			nombre: e.target[e.target.selectedIndex].id
		});
		console.log(e)
	};

	const cobroError = () => {
		setCobroComplete(true)
		setOpenDialog(true)
		setTitle('Ha ocurrido un error al generar el cobro')
		// handleCloseGenerar()
	}

	const cobroSuccess = () => {
		setCobroComplete(true)
		setOpenDialog(true)
		setTitle('El cobro ha sido generado')
		// handleCloseGenerar()
	}

	const [generarCobroMutation, { loading: loadingGenerarCobro }] = useGenerarCobroMutation({
		onCompleted: () => { cobroSuccess(); },
		onError: () => { cobroError(); }
	});

	const handleDialogClose = () => {
		setOpenDialog(false)
	}
	const handleClickOpenAlert = () => {
		setOpen(true);
	};

	const handleCloseAlert = () => {
		setOpen(false);
		setCobroComplete(false);
	};

	const handleConfirmEntrega = () => {
		console.log(generarCobroMutation)
		console.log('nombre: ', corredor.id)
		console.log('monto: ', formatNumbers(monto))
		handleClickOpenAlert()
	}

	const handleMuteEntrega = async () => {
		console.log('id of cobrador: ', auth?.user?.cobrador?.id)
		await generarCobroMutation({
			variables: {
				cobrador_id: Number(auth?.user?.cobrador?.id),
				corredor_id: Number(corredor?.id),
				referencia: `${dayjs(Date.now()).format("DD-MM-YYYY")}-${dayjs(Date.now()).format("HH:mm")}+${corredor?.nombre}+${monto}`,
				monto: Number(monto)
			}
		})
		refetchData()
	}

	const searchByCobrador = (e) => {
		e.preventDefault();
		setCobrador(e.target.value);
		setCobradorDataNumber(data.filter(c => c.numero.toString() === e.target.value.toString()))
		console.log("cobrador Data Number: ", cobradorDataNumber);
	}

	const searchByCI = (e) => {
		e.preventDefault();
		setCICobrador(e.target.value)
	}

	// const resetFilter = () => {
	// setCobrador(0);
	// setCICobrador(0);
	// }

	const cobradorDataCi = data.filter(c => c.cedula.toString() === ciCobrador.toString())

	useEffect(() => {

	}, [ciCobrador])

	useEffect(() => {

	}, [corredor, loadingGenerarCobro])

	if (loadingGenerarCobro) return <Loading />

	return (
		<Box
			sx={{
				minHeight: '100%',
				p: 3
			}}
		>
			<Paper
				elevation={12}
				sx={{
					maxWidth: 320,
					mx: 'auto',
					p: 2
				}}
			>
				<Typography
					color="textSecondary"
					variant="h6"
				>
					Generar Entrega
				</Typography>
				<Box sx={{ mt: 3 }}>
					<TextField
						label="Buscar por &#8470; de corredor"
						// onClick={resetFilter}
						onChange={searchByCobrador}
						value={cobrador}
						name="ncorredor"
						variant="outlined"
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						fullWidth
					/>
				</Box>
				<Box sx={{ mt: 3 }}>
					<TextField
						label="Buscar por CI"
						// onClick={resetFilter}
						onChange={searchByCI}
						value={ciCobrador}
						name="ci"
						variant="outlined"
						type="number"
						InputLabelProps={{
							shrink: true,
						}}
						fullWidth
					/>
				</Box>
				<Box sx={{ mt: 3, mb: 3 }}>
					<FormControl style={{ minWidth: '100%' }}>
						<NativeSelect
							value={corredor.id}
							name="nombre"
							onChange={handleCorredorChange}
							inputProps={{ 'aria-label': 'nombre' }}
						>
							<option value="" id="0"
								disabled>
								Corredor
							</option>
							{/* eslint-disable-next-line no-nested-ternary */}
							{cobrador > 0 ? cobradorDataNumber.map(c => <option key={c.id} id={c.numero} value={c.id}>{c.nombre}</option>)
								: ciCobrador > 0 ?
									cobradorDataCi.map(c => <option key={c.id} id={c.numero} value={c.id}>{c.nombre}</option>)
									: data.map(c => <option key={c.id} id={c.numero} value={c.id}>{c.nombre}</option>)
							}
						</NativeSelect>
						<FormHelperText>Corredor requerido</FormHelperText>
					</FormControl>
				</Box>
				<Box sx={{ mt: 3 }}>
					<TextField
						label="Monto"
						name="monto"
						required
						onChange={(e) => { e.preventDefault(); setMonto(Number(e.target.value)) }}
						value={monto}
						variant="outlined"
						type="number"
						fullWidth
					/>
				</Box>
				<Box sx={{ mt: 3 }}>
					{(corredor.id.toString().trim() === '' || monto <= 0)
						?
						<Button
							color="primary"
							fullWidth
							variant="contained"
							disabled
							sx={{ marginBottom: 1 }}
						>
							Generar Entrega
						</Button>
						:
						<Button
							color="primary"
							fullWidth
							variant="contained"
							sx={{ marginBottom: 1 }}
							onClick={() => handleConfirmEntrega()}
						>
							Generar Entrega
						</Button>
					}
					<Button
						color="secondary"
						fullWidth
						variant="contained"
						onClick={() => handleCloseGenerar()}
					>
						Cancelar
					</Button>

					<Dialog
						open={open}
						onClose={handleCloseAlert}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">Confirma debitar {formatNumbers(monto)} del corredor &#8470; {corredor.nombre}</DialogTitle>
						<DialogActions>
							<Button onClick={handleCloseAlert}
								color="primary">
								Cancelar
							</Button>
							<Button onClick={handleMuteEntrega}
								color="primary"
								autoFocus>
								Aceptar
							</Button>
						</DialogActions>
					</Dialog>
					{cobroComplete
						?
						<DialogComplete openDialog={openDialog}
							handleDialogClose={handleDialogClose}
							title={title}
							handleCloseGenerar={handleCloseGenerar} />
						:
						null
					}
				</Box>
			</Paper>
		</Box>
	)
};

Generar.propTypes = {
	handleCloseGenerar: PropTypes.any.isRequired,
	data: PropTypes.any,
	refetchData: PropTypes.func
};

export default Generar;
