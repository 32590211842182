import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'

interface UMNContextI {
    UMN: any;
    setUMN: any;
  }

export const UMNContext = createContext<UMNContextI>({
    UMN: [0],
    setUMN: () => {}
});

export const UMNProvider = (props) => {
  const { children } = props;
  const auth = useAuth()
  console.log('auth from context umn',auth) 
  const [UMN, setUMN] = useState(localStorage.getItem("UMNid") || auth?.user?.umns[0]?.id);
  console.log('auth from context umn',UMN)
	

	useEffect(() => {
    if(!UMN){
      localStorage.setItem('UMNid', auth?.user?.umns[0]?.id)
    }
    localStorage.setItem('UMNid', UMN)
    console.log("UMN selected:",UMN)
	}, [UMN])

  return (
    <UMNContext.Provider

      value={{
        UMN,
        setUMN,
      }}
    >
      {children}
    </UMNContext.Provider>
  );
};

UMNProvider.propTypes = {
  children: PropTypes.node.isRequired
};
