import type { FC } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import "../../../css/estiloGerencia.css"
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import Actions from '../../ActionsAgenciaProducto';
import Scrollbar from '../../Scrollbar';
import ArrowRight from '../../../icons/ArrowRight';
import formatNumbers from '../../../utils/formatNumbers';

interface TableRecaudacionAgenciaGerenciaProps {
  items: any[];
  idProducto?: any;
  RouterLink?: any;
  xlsx?: any;
}

const TableRecaudacionAgenciaGerencia: FC<TableRecaudacionAgenciaGerenciaProps> = (props) => {
    const { items, idProducto, xlsx, ...other } = props;
    const totales = {
        cantidad: 0,
        bruto: 0,
        premio: 0,
        percent: 0,
        comisionA: 0,
        comisionC: 0,
        totalPremiados: 0,
        neto: 0
    }


    useEffect(() => {}, [items])

    return (
    <>
        <Card {...other}>
            <CardHeader
                action={<Actions xlsx={xlsx}
                items={items}
                idProducto={idProducto}
                />}
                title={`Recaudacion por Agencia. Juego: ${items[0].tipo_juego}`}
            />
            <Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader
                      className="tableColor"
                      style={{ height: 150 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Agencia
																	</Typography>
                                </TableCell >
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Cantidad
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premiados
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Total Bruto
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premio
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    %
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comisión Agencia
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comisión Corredor
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Neto
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Actions
																	</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => {
                                totales.cantidad += parseInt(item.cantidad, 10)
                                totales.bruto += parseInt(item.total, 10)
                                totales.premio += parseInt(item.premio, 10)
                                totales.comisionA += parseInt(item.totalComisionA, 10)
                                totales.comisionC += parseInt(item.totalComisionC, 10)
                                totales.totalPremiados += parseInt(item.cantidad_premiados, 10)
                                totales.neto += (parseInt(item.total, 10) - parseInt(item.premio, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))
                                return <TableRow
                                key={item.id}
                                >
                                    <TableCell align="left">
                                        <Typography
                                          variant="subtitle1"
                                        >
                                            {`AG ${item.agencia_numero}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
																				  { formatNumbers(item.cantidad) }
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
                                          { formatNumbers(item.cantidad_premiados)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
                                            { `${formatNumbers(item.total)}` }
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
                                            { item.premio? formatNumbers(item.premio): "-" }
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color={item.premio > item.monto * 0.54? "red": "textPrimary"}
                                            sx={{fontSize: item.premio > item.monto * 0.54? "20px": "16px"}}
                                            variant="subtitle1"
                                        >
                                            {((parseInt(item.premio, 10) / parseInt(item.total, 10)) * 100).toFixed(2)}%
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { formatNumbers(parseInt(item.totalComisionA, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { formatNumbers(parseInt(item.totalComisionC, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { item.premio ? formatNumbers((parseInt(item.total, 10) - parseInt(item.premio, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))): formatNumbers((parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10)))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Grid
                                        display='flex'
                                        direction='row'
                                        justifyContent="flex-end"
                                      >
                                        <Button
                                            component={RouterLink}
                                            to={`/planillaGeneral/producto/gerencia/300/${item?.agencia_numero}/${item?.juego_id}`}
                                            sx={{maxHeight:10}}
                                        >
                                            <IconButton>
                                            <ArrowRight
                                                fontSize="small"
                                                style={{color: "#D1236C"}}
                                            />
                                            </IconButton>
                                        </Button>
                                      </Grid>
                                    </TableCell>
                                </TableRow>
                                }
                            )}
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                    >
                                        Total
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
									                      color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.cantidad)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
									                      color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.totalPremiados)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
                                        color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.bruto)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.premio)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {((totales.premio / totales.bruto) * 100).toFixed(2)}%
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.comisionA)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.comisionC)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.neto)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                />
                            </TableRow>
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
        </Card>
    </>)
};

TableRecaudacionAgenciaGerencia.propTypes = {
  items: PropTypes.array.isRequired,
  idProducto: PropTypes.any,
  xlsx: PropTypes.any
};

export default TableRecaudacionAgenciaGerencia;
