import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  // Box,
  Button,
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';

const AccountPopover: FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      localStorage.clear();
      window.location.reload();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button
        color="secondary"
        fullWidth
        onClick={handleLogout}
        variant="contained"
      >
        Cerrar Sesión
      </Button>
    </>
  );
};

export default AccountPopover;
