/* eslint-disable no-nested-ternary */
import { useEffect, useContext, useState } from 'react';
import type { FC } from 'react';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Grid,
  Link,
  Typography,
  Button,
  List,
  ListSubheader,
  IconButton,
  TextField
} from '@material-ui/core';
import FilterGerencia from 'src/components/dashboard/gerencia/FilterGerencia';
import AgenciesFilter from 'src/components/agencias/AgenciesFilter';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ArchiveIcon from '@material-ui/icons/Archive';
import TheatersIcon from '@material-ui/icons/Theaters';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import dayjs from 'dayjs'
import BallotIcon from '@material-ui/icons/Ballot';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { GiSherlockHolmes, GiReceiveMoney, GiTicket } from 'react-icons/gi'
import ArrowRight from '../../icons/ArrowRight';
import ArrowLeft from '../../icons/ArrowLeft';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import BriefcaseIcon from '../../icons/Briefcase';
import { UMNContext, RoleContext, AgenciaContext } from '../../contexts'

import useAuth from '../../hooks/useAuth';

import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import HomeIcon from '../../icons/Home'
import Phone from '../../icons/Phone';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import Label from '../Label'

import { useGetUmnsQuery, useGetAgencyByUmnQuery, useZonaAgenciaQuery, useAllAgenciasQuery } from '../../generated/graphql'

import { DateAndTimePickers } from '../Date/DateFilter'
import { DateConsumer } from '../../contexts/DateContext'

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const [zona, setZona] = useState([0]);
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const { UMN, setUMN } = useContext(UMNContext);
  const { agencia, dataAgencia } = useContext(AgenciaContext);
  const { role } = useContext(RoleContext)
  const umnId = UMN

  useEffect(() => {
    // render select agencia with context
  }, [dataAgencia])

  const {data: dataUmn } = useGetUmnsQuery({variables: {agencia, zona}, fetchPolicy: 'network-only'})
  const {data: agenciaNumber} = useGetAgencyByUmnQuery({variables: {id:umnId}, fetchPolicy: 'network-only'})
  const {data: {getZonasByAgencia} = {}} = useZonaAgenciaQuery({variables: {agencia: agenciaNumber?.umn?.agencia_numero}})
  const {data: { allAgencies } = {}} = useAllAgenciasQuery();

  useEffect(() => {}, [allAgencies])
  let umnS = role?.includes('tdp_operativa') || role?.includes('finanzas') || role?.includes('tesoreria') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') ? dataUmn?.allUmns : user.umns

  let sections;

  if(agencia[0] === 1) {
    console.log("agencia posicion 0", agencia[0])
    console.log("agencia posicion 1", agencia[1])
    console.log("agencia dos posiciones", agencia)
    sections = role?.includes('tdp_gerencia', 'presidencia') ?
    [
      {
        title: 'Gerencia',
        items: [
          {
            title: 'General',
            path: '/gerencia',
            icon: <HomeIcon fontSize="small" />,
            role: ['tdp_gerencia', 'presidencia']
          },
        ]
      },
      {
        title: 'Reportes',
        items: [

          {
            title: 'Reportes',
            path: '/conajzar/reporteFinanciero',
            icon: <UsersIcon fontSize="small" />,
            role: ['tdp_gerencia', 'presidencia'],
            children: [
              {
                title: 'Montos por Boletas',
                path: '/boletasPorMonto',
              },
              {
                title: 'Historial de Sorteos',
                path: '/historialDeSorteos',
              },
              {
                title: 'Ocurrencias',
                path: '/ocurrenciasGlobales'
              },
              {
                title: 'Ocurrencias de Tómbola',
                path: '/ocurrenciasTombola'
              },
              {
                title: 'Bolillero',
                path: '/bolillero'
              },
              {
                title: 'Cuentas',
                path: '/estadoCuenta'
              }
            ]
          },
        ]
      },
      {
        title: 'Raspadita',
        items: [
          {
            title: 'Asignaciones',
            path: '/raspaditaAsignaciones',
            icon: <GiTicket fontSize="small" />,
            role: ['presidencia']
          },
          {
            title: 'Stock',
            path: '/stock/raspadita-tipo-juego',
            icon: <GiTicket fontSize="small" />,
            role: ['presidencia']
          }
        ]
      },
      {
        title: 'Reporte Raspadita',
        items: [
          {
            title: 'Reportes Raspadita',
            path: '/raspadita',
            icon: <BallotIcon fontSize="small" />,
            role: ['presidencia', 'tdp_gerencia'],
            children: [
              {
                title: 'Ganadores',
                path: '/ganadores',
              },
              {
                title: 'Facturación',
                path: '/facturacion-raspadita-gerencia',
              }
            ]
          }
        ]
      },
      {
        title: 'Gerencia',
        items: [
          {
            title: 'Sherlock',
            path: '/mode-holmes',
            icon: <GiSherlockHolmes fontSize="small" />,
            role: ['presidencia']
          }
        ]
      },
    ]
  :
  sections = role?.includes('raspa_operativa') ?
    [
      {
        title: 'Reporte Raspadita',
        items: [
          {
            title: 'Reportes Raspadita',
            path: '/raspadita',
            icon: <BallotIcon fontSize="small" />,
            role: ['raspa_operativa'],
            children: [
              {
                title: 'Ganadores',
                path: '/ganadores',
              },
              {
                title: 'Facturación',
                path: '/facturacion-raspadita',
              },
              {
                title: 'Premios',
                path: '/raspaditas/premios'
              },
            ]
          },
          {
            title: 'Ventas',
            path: '/raspadita',
            icon: <BallotIcon fontSize="small" />,
            role: ['raspa_operativa'],
            children: [
              {
                title: 'Libros Vendidos',
                path: '/libros-vendidos',
              }
            ]
          },
          {
            title: 'Stock',
            path: '/RaspaditaOperativa',
            icon: <BallotIcon fontSize="small" />,
            role: ['raspa_operativa'],
            children: [
                {
                  title: 'Stock General',
                  path: '/stock/raspadita-tipo-juego',
                },
                {
                  title: 'Stock Pallets',
                  path: '/stock/pallets',
                },
                {
                  title: 'Días asignación',
                  path: '/stock/raspadita-tipo-juego-dias-asignados'
                },
              ]
          },
          {
            title: 'Asignadas por Corredor',
            path: '/asignadas-por-corredor',
            icon: <GiTicket fontSize="small" />
          },
          {
            title: 'Ventas por Corredor',
            path: '/ventas-por-corredor',
            icon: <GiTicket fontSize="small" />,
            role: ['presidencia', 'gerencia']
          }
        ]
      },

    ]
  :
  role?.includes('conajzar') ?
  [
    {
      title: 'Conajzar',
      items: [
        // {
        //   title: 'Informe de cuenta del jugador',
        //   path: '/estadoJugador',
        //   icon: <AssignmentIndIcon fontSize="small" />,
        //   role: ['conajzar']
        // },
        {
          title: 'Reporte Financiero',
          path: '/reporteFinanciero',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Venta por agencias',
              path: '/ventaAgencias',
            },
            {
              title: 'Medios electrónicos',
              path: '/mediosElectronicos',
            },
            {
              title: 'Terminales',
              path: '/mediosTerminales'
            },
          ]
        },
        {
          title: 'Reportes del sistema',
          path: '/reporteSistema',
          icon: <UserIcon fontSize="small" />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Agencia',
          path: '/reporteAgencia',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Diaria',
          path: '/reporteFacturacionDiaria',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de eventos significativos',
          path: '/reporteDeEventos',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Intentos de login fallidos',
              path: '/loginFallidos',
            },
            {
              title: 'Uptime del sistema',
              path: '/uptimeSistema',
            },
            {
              title: 'Anulaciones',
              path: '/anulacionesEventos'
            },
            {
              title: 'Update de software',
              path: '/softwareUpdate'
            },
            {
              title: 'Logs de parametros del sistema',
              path: '/parametrosSistema'
            },
            {
              title: 'Hearbeats',
              path: '/hearbeatsEventos'
            },
            {
              title: 'Registro de asistencias',
              path: '/registroAsistencia'
            },
            {
              title: 'Auditoria de Firewall',
              path: '/auditoriaFirewall'
            },
            {
              title: 'Reporte de acceso remoto',
              path: '/accesoRemoto'
            },
            {
              title: 'Apuestas Abortadas',
              path: '/apuestasAbortadas'
            },
            {
              title: 'Cambios de posibilidades Comision/Porcentaje',
              path: '/cambiosPosibilidades'
            },
            {
              title: 'Errores de incompatibilidad',
              path: '/erroresIncompatibildad'
            },
          ]
        },
        {
          title: 'Reporte de Pago del premio',
          path: '/reportePagoPremios',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de premios superiores',
          path: '/reportesPagoSuperior',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de sorteos',
          path: '/informeLiquidaciones',
          icon: <BallotIcon fontSize='small' />,
          role: ['conajzar']
        },
        // {
        //   title: 'Reporte de Raspaditas',
        //   path: '/reporteDeRaspadita',
        //   icon: <BallotIcon fontSize='small' />,
        //   role: ['conajzar'],
        //   children: [
        //     {
        //       title: 'Facturación',
        //       path: '/facturacion-raspadita',
        //     },
        //     {
        //       title: 'Ganadores',
        //       path: '/ganadores',
        //     }
        //   ]
        // },
      ]
    },
  ] :
  role?.includes('conajzar_raspa') ?
  [
    {
      title: 'Raspadita',
      items: [
        {
          title: 'Reporte de Raspaditas',
          path: '/reporteDeRaspadita',
          icon: <BallotIcon fontSize='small' />,
          role: ['conajzar_raspa'],
          children: [
            {
              title: 'Facturación',
              path: '/facturacion-raspadita',
            },
            {
              title: 'Ganadores',
              path: '/ganadores',
            }
          ]
        },
      ]
    },
  ]
  :
  role?.includes('cobrador') ?
  [
    {
      title: 'Cobrador',
      items: [
        {
          title: 'Entregas',
          path: '/entregas',
          icon: <ShoppingBagIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Estados Corredor',
          path: '/estadosCorredor',
          icon: <AssignmentIndIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
        },
        {
          title: 'Cuenta',
          path: '/profile',
          icon: <UserIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Tickets',
          path: '/tickets',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Billetaje',
          path: '/billetaje',
          icon: <DirectionsBusIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        }
      ]
    },
  ] :
    [
    {
      title: 'Gerencia',
      items: [
        {
          title: 'General',
          path: '/gerencia',
          icon: <HomeIcon fontSize="small" />,
          role: ['tdp_gerencia', 'presidencia']
        },
      ]
    },
    {
      title: 'Reportes',
      items: [
        {
          title: 'Reportes',
          path: '/conajzar/reporteFinanciero',
          icon: <UsersIcon fontSize="small" />,
          role: ['tdp_gerencia', 'presidencia'],
          children: [
            {
              title: 'Montos por Boletas',
              path: '/boletasPorMonto',
            },
            {
              title: 'Historial de Sorteos',
              path: '/historialDeSorteos',
            },
            {
              title: 'Ocurrencias',
              path: '/ocurrenciasGlobales'
            },
            {
              title: 'Ocurrencias de Tómbola',
              path: '/ocurrenciasTombola'
            },
            {
              title: 'Bolillero',
              path: '/bolillero'
            },
            {
              title: 'Cuentas',
              path: '/estadoCuenta'
            }
          ]
        },
      ]
    },
    {
      title: 'Raspadita',
      items: [
        {
          title: 'Asignaciones',
          path: '/raspaditaAsignaciones',
          icon: <GiTicket fontSize="small" />,
          role: ['presidencia']
        },
        {
          title: 'Stock',
          path: '/stock/raspadita-tipo-juego',
          icon: <GiTicket fontSize="small" />,
          role: ['presidencia', 'finanzas', 'tdp_gerencia']
        }
      ]
    },
    {
      title: 'Reporte Raspadita',
      items: [
        {
          title: 'Reportes Raspadita',
          path: '/raspadita',
          icon: <BallotIcon fontSize="small" />,
          role: ['presidencia', 'tdp_gerencia', 'raspa_operativa'],
          children: [
            {
              title: 'Ganadores',
              path: '/ganadoresGerencia',
            },
            {
              title: 'Facturación',
              path: '/facturacion-raspadita-gerencia',
            }
          ]
        }
      ]
    },
    {
      title: 'Sherlock',
      items: [
        {
          title: 'Sherlock',
          path: '/mode-holmes',
          icon: <GiSherlockHolmes fontSize="small" />,
          role: ['presidencia']
        }
      ]
    },
    {
      title: 'Conajzar',
      items: [
        // {
        //   title: 'Informe de cuenta del jugador',
        //   path: '/conajzar/estadoJugador',
        //   icon: <AssignmentIndIcon fontSize="small" />,
        //   role: ['conajzar']
        // },
        {
          title: 'Reporte Financiero',
          path: '/conajzar/reporteFinanciero',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Venta por agencias',
              path: '/ventaAgencias',
            },
            {
              title: 'Medios electrónicos',
              path: '/mediosElectronicos',
            },
            {
              title: 'Terminales',
              path: '/mediosTerminales'
            },
          ]
        },
        {
          title: 'Reportes del sistema',
          path: '/conajzar/reporteSistema',
          icon: <UserIcon fontSize="small" />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Agencia',
          path: '/conajzar/reporteAgencia',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Diaria',
          path: '/reporteFacturacionDiaria',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de eventos significativos',
          path: '/reporteDeEventos',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Intentos de login fallidos',
              path: '/loginFallidos',
            },
            {
              title: 'Uptime del sistema',
              path: '/uptimeSistema',
            },
            {
              title: 'Anulaciones',
              path: '/anulacionesEventos'
            },
            {
              title: 'Update de software',
              path: '/softwareUpdate'
            },
            {
              title: 'Logs de parametros del sistema',
              path: '/parametrosSistema'
            },
            {
              title: 'Hearbeats',
              path: '/hearbeatsEventos'
            },
            {
              title: 'Registro de asistencias',
              path: '/registroAsistencia'
            },
            {
              title: 'Auditoria de Firewall',
              path: '/auditoriaFirewall'
            },
            {
              title: 'Reporte de acceso remoto',
              path: '/accesoRemoto'
            },
            {
              title: 'Apuestas Abortadas',
              path: '/apuestasAbortadas'
            },
            {
              title: 'Cambios de posibilidades Comision/Porcentaje',
              path: '/cambiosPosibilidades'
            },
            {
              title: 'Errores de incompatibilidad',
              path: '/erroresIncompatibildad'
            },
          ]
        },
        {
          title: 'Reporte de Pago del premio',
          path: '/conajzar/reportePagoPremios',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de premios superiores',
          path: '/conajzar/reportesPagoSuperior',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de sorteos',
          path: '/informeLiquidaciones',
          icon: <BallotIcon fontSize='small' />,
          role: ['conajzar']
        },
      ]
    },
    {
      title: 'Agencias',
      items: [
        {
          title: 'Gestionar',
          path: `/`,
          className: 'menu-icon',
          icon: <BriefcaseIcon fontSize="small" />,
          role: ['tesoreria']
        },
      ]
    },
    {
      title: 'Agencias',
      items: [
        {
          title: 'Gestionar',
          path: `/`,
          className: 'menu-icon',
          icon: <BriefcaseIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        },
        // TODO: planilla Agencias
        // {
        //   title: 'Planilla Agencias',
        //   path: `/planillaAgencias`,
        //   className: 'menu-icon',
        //   icon: <BriefcaseIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        // },
        // {
        //   title: 'Planilla',
        //   path: `/planilla`,
        //   icon: <DocumentText fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        // },
        {
          title: 'Planilla Premiados',
          path: `/premiados/0`,
          icon: <AccountBalanceWalletIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        },
        // {
        //   title: 'Planilla Jugadas',
        //   path: `/jugadas`,
        //   icon: <CasinoIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        // },
        {
          title: 'Pago Premios',
          path: `/pagoDePremios`,
          icon: <GiReceiveMoney fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        },
        {
          title: 'Planilla Cobros',
          path: `/cobros`,
          icon: <ReceiptIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
        },
        {
          title: 'Planilla Cobros Prueba',
          path: `/cobros2`,
          icon: <ReceiptIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
        },
        {
          title: 'Deposito Bancario',
          path: `/depositosAgencia`,
          icon: <AccountBalanceIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
        },
        {
          title: 'Lista de Usuarios',
          path: '/users',
          icon: <UserIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        },
        {
          title: 'Usuarios Externos',
          path: '/usuariosExternos',
          icon: <RecentActorsIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'tdp_operativa']
        },
        {
          title: 'Lista de Dispositivos',
          path: '/devices',
          icon: <Phone fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'agencia']
        },
        {
          title: 'Deposito UMN',
          path: '/depositoUmn',
          icon: <ArchiveIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia']
        },
        // {
        //   title: 'Extracto UMN',
        //   path: '/extractoUmn',
        //   icon: <AssignmentIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn']
        // },
        {
          title: 'Boletas',
          path: '/boletas',
          icon: <TheatersIcon fontSize="small" />,
          role: ['admin_juego', 'tdp_operativa']
        },
      ]
    },
    {
      title: 'Stock',
      items: [
        {
          title: 'Terminales',
          path: `/stock/terminal`,
          className: 'menu-icon',
          icon: <SmartphoneIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'terminal']
        },
      ]
    },
    {
      title: 'Cobrador',
      items: [
        {
          title: 'Entregas',
          path: '/entregas',
          icon: <ShoppingBagIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'agencia', 'umn']
        },
        {
          title: 'Estados Corredor',
          path: '/estadosCorredor',
          icon: <AssignmentIndIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
        },
        {
          title: 'Cuenta',
          path: '/profile',
          icon: <UserIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Tickets',
          path: '/tickets',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Billetaje',
          path: '/billetaje',
          icon: <DirectionsBusIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        }
      ]
    },
    {
      title: 'Operativa',
      items: [
        {
          title: 'Quiniela',
          path: '/SorteosBase',
          icon: <UsersIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas' ,'umn', 'agencia'],
          children: [
            {
              title: 'Anulaciones',
              path: '/anulaciones',
            },
            {
              title: 'Ajustes',
              path: '/ajustes',
            },
            // {
            //   title: 'Ajustes UMN',
            //   path: '/ajustesUmn',
            // },
            {
              title: 'Agenda sorteos',
              path: '/SorteosBase'
            },
            {
              title: 'Sorteos',
              path: '/Sorteos'
            },
            {
              title: 'Número limitados',
              path: '/numerosLimitados'
            }
          ]
        },
        {
          title: 'Raspadita',
          path: '/RaspaditaOperativa',
          icon: <UsersIcon fontSize="small" />,
          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
          children: [
            // {
            //   title: 'Corregir Premios',
            //   path: 'raspadita/correcion-premios',
            // },
            {
              title: 'Diferencia Premios',
              path: 'raspadita/diferencia-premios',
            }
          ]
        },
      ]
    },
    {
      title: 'Seguimiento Financiero',
      items: [
        {
          title: 'Resumen General',
          path: '/resumenGeneral',
          icon: <BriefcaseIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
        },
        // {
        //   title: 'Depositos',
        //   path: '/resumenDepositos',
        //   icon: <ArchiveIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
        // },
        // {
        //   title: 'Ajustes',
        //   path: '/resumenAjustes',
        //   icon: <SettingsIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'cobrador', 'umn', 'agencia']
        // },
      ]
    },
    {
      title: 'Raspadita',
      items: [
        {
          title: 'Stock',
          path: '/stock/raspadita-tipo-juego/',
          icon: <GiTicket fontSize="small" />,
          role: ['presidencia', 'finanzas']
        }
      ]
    },
    {
      title: 'Raspaditas',
      items: [
        {
          title: 'Raspaditas',
          path: '/raspaditas',
          icon: <BallotIcon fontSize="small" />,
          role: ['agencia', 'umn', 'admin_juego', 'tdp_operativa'],
          children: [
            {
              title: 'Recepcion de Cajas',
              path: '/raspaditas/cajas',
            },
            {
              title: 'Recepcion de Libros',
              path: '/raspaditas/libros',
            },
            {
              title: 'Asignacion a Corredores',
              path: '/raspaditas/corredores'
            },
            {
              title: 'Premios',
              path: '/raspaditas/premios'
            },
            {
              title: 'Stock',
              path: '/raspaditas/stock'
            },
            {
              title: 'Boletas',
              path: 'raspaditas',
              children: [
                {
                  title: 'Asignadas a corredores',
                  path: '/raspaditas/boletasAsignadasACorredores'
                },
                {
                  title: 'Asignadas por tipo Juego',
                  path: '/raspaditas/boletasAsignadasCorredores'
                },
                {
                  title: 'Vendidas por corredor',
                  path: '/raspaditas/boletasVendidasCorredores'
                }
              ]
            }
          ]
        },
      ]
    },
    {
      title: 'Raspadita asignacion Libro a Umn',
      items: [
        {
          title: 'Asignacion a UMN',
          path: `/raspaditas/asignacion-umn`,
          className: 'menu-icon',
          icon: <BallotIcon fontSize="small" />,
          role: ['admin_juego', 'agencia',]
        },
      ]
    },
    {
      title: 'Raspadita (AG departamental)',
      items: [
        {
          title: 'Recepción de caja',
          path: `/raspaditas/recepcion-caja`,
          className: 'menu-icon',
          icon: <BallotIcon fontSize="small" />,
          role: ['admin_juego', 'agencia',]
        },
      ]
    },
  ];
  } else {
    sections = role?.includes('tdp_gerencia', 'presidencia') ?
    [
      {
        title: 'Gerencia',
        items: [
          {
            title: 'General',
            path: '/gerencia',
            icon: <HomeIcon fontSize="small" />,
            role: ['tdp_gerencia', 'presidencia']
          },
        ]
      },
      {
        title: 'Reportes',
        items: [

          {
            title: 'Reportes',
            path: '/conajzar/reporteFinanciero',
            icon: <UsersIcon fontSize="small" />,
            role: ['tdp_gerencia', 'presidencia'],
            children: [
              {
                title: 'Montos por Boletas',
                path: '/boletasPorMonto',
              },
              {
                title: 'Historial de Sorteos',
                path: '/historialDeSorteos',
              },
              {
                title: 'Ocurrencias',
                path: '/ocurrenciasGlobales'
              },
              {
                title: 'Ocurrencias de Tómbola',
                path: '/ocurrenciasTombola'
              },
              {
                title: 'Bolillero',
                path: '/bolillero'
              },
            ]
          },
        ]
      },
      {
        title: 'Raspadita',
        items: [
          {
            title: 'Asignaciones',
            path: '/raspaditaAsignaciones',
            icon: <GiTicket fontSize="small" />,
            role: ['presidencia']
          }
        ]
      },
      {
        title: 'Reporte Raspadita',
        items: [
          {
            title: 'Reportes Raspadita',
            path: '/raspadita',
            icon: <BallotIcon fontSize="small" />,
            role: ['presidencia', 'tdp_gerencia'],
            children: [
              {
                title: 'Ganadores',
                path: '/ganadores',
              },
              {
                title: 'Facturación',
                path: '/facturacion-raspadita-gerencia',
              }
            ]
          }
        ]
      },
      {
        title: 'Gerencia',
        items: [
          {
            title: 'Sherlock',
            path: '/mode-holmes',
            icon: <GiSherlockHolmes fontSize="small" />,
            role: ['presidencia']
          }
        ]
      },
    ]
  :
  sections = role?.includes('raspa_operativa') ?
    [
      {
        title: 'Reporte Raspadita',
        items: [
          {
            title: 'Reportes Raspadita',
            path: '/raspadita',
            icon: <BallotIcon fontSize="small" />,
            role: ['raspa_operativa'],
            children: [
              {
                title: 'Ganadores',
                path: '/ganadores',
              },
              {
                title: 'Facturación',
                path: '/facturacion-raspadita',
              }
            ]
          }
        ]
      },
    ]
  :
  role?.includes('conajzar') ?
  [
    {
      title: 'Conajzar',
      items: [
        // {
        //   title: 'Informe de cuenta del jugador',
        //   path: '/estadoJugador',
        //   icon: <AssignmentIndIcon fontSize="small" />,
        //   role: ['conajzar']
        // },
        {
          title: 'Reporte Financiero',
          path: '/reporteFinanciero',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Venta por agencias',
              path: '/ventaAgencias',
            },
            {
              title: 'Medios electrónicos',
              path: '/mediosElectronicos',
            },
            {
              title: 'Terminales',
              path: '/mediosTerminales'
            },
          ]
        },
        {
          title: 'Reportes del sistema',
          path: '/reporteSistema',
          icon: <UserIcon fontSize="small" />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Agencia',
          path: '/reporteAgencia',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Diaria',
          path: '/reporteFacturacionDiaria',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de eventos significativos',
          path: '/reporteDeEventos',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Intentos de login fallidos',
              path: '/loginFallidos',
            },
            {
              title: 'Uptime del sistema',
              path: '/uptimeSistema',
            },
            {
              title: 'Anulaciones',
              path: '/anulacionesEventos'
            },
            {
              title: 'Update de software',
              path: '/softwareUpdate'
            },
            {
              title: 'Logs de parametros del sistema',
              path: '/parametrosSistema'
            },
            {
              title: 'Hearbeats',
              path: '/hearbeatsEventos'
            },
            {
              title: 'Registro de asistencias',
              path: '/registroAsistencia'
            },
            {
              title: 'Auditoria de Firewall',
              path: '/auditoriaFirewall'
            },
            {
              title: 'Reporte de acceso remoto',
              path: '/accesoRemoto'
            },
            {
              title: 'Apuestas Abortadas',
              path: '/apuestasAbortadas'
            },
            {
              title: 'Cambios de posibilidades Comision/Porcentaje',
              path: '/cambiosPosibilidades'
            },
            {
              title: 'Errores de incompatibilidad',
              path: '/erroresIncompatibildad'
            },
          ]
        },
        {
          title: 'Reporte de Pago del premio',
          path: '/reportePagoPremios',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de premios superiores',
          path: '/reportesPagoSuperior',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de sorteos',
          path: '/informeLiquidaciones',
          icon: <BallotIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de Raspaditas',
          path: '/reporteDeRaspadita',
          icon: <BallotIcon fontSize='small' />,
          role: ['conajzar'],
          children: [
            {
              title: 'Facturación',
              path: '/facturacion-raspadita',
            },
            {
              title: 'Ganadores',
              path: '/ganadores',
            }
          ]
        },
      ]
    },
  ] :
  role?.includes('conajzar_raspa') ?
  [
    {
      title: 'Raspadita',
      items: [
        {
          title: 'Reporte de Raspaditas',
          path: '/reporteDeRaspadita',
          icon: <BallotIcon fontSize='small' />,
          role: ['conajzar_raspa'],
          children: [
            {
              title: 'Facturación',
              path: '/facturacion-raspadita',
            },
            {
              title: 'Ganadores',
              path: '/ganadores',
            }
          ]
        },
      ]
    },
  ] :
  role?.includes('cobrador') ?
  [
    {
      title: 'Cobrador',
      items: [
        {
          title: 'Entregas',
          path: '/entregas',
          icon: <ShoppingBagIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Estados Corredor',
          path: '/estadosCorredor',
          icon: <AssignmentIndIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
        },
        {
          title: 'Cuenta',
          path: '/profile',
          icon: <UserIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Tickets',
          path: '/tickets',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Billetaje',
          path: '/billetaje',
          icon: <DirectionsBusIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        }
      ]
    },
  ] :
    [
    {
      title: 'Gerencia',
      items: [
        {
          title: 'General',
          path: '/gerencia',
          icon: <HomeIcon fontSize="small" />,
          role: ['tdp_gerencia', 'presidencia']
        },
      ]
    },
    {
      title: 'Reportes',
      items: [
        {
          title: 'Reportes',
          path: '/conajzar/reporteFinanciero',
          icon: <UsersIcon fontSize="small" />,
          role: ['tdp_gerencia', 'presidencia'],
          children: [
            {
              title: 'Montos por Boletas',
              path: '/boletasPorMonto',
            },
            {
              title: 'Historial de Sorteos',
              path: '/historialDeSorteos',
            },
            {
              title: 'Ocurrencias',
              path: '/ocurrenciasGlobales'
            },
            {
              title: 'Ocurrencias de Tómbola',
              path: '/ocurrenciasTombola'
            },
            {
              title: 'Bolillero',
              path: '/bolillero'
            },
          ]
        },
      ]
    },
    {
      title: 'Raspadita',
      items: [
        {
          title: 'Asignaciones',
          path: '/raspaditaAsignaciones',
          icon: <GiTicket fontSize="small" />,
          role: ['presidencia']
        }
      ]
    },
    {
      title: 'Reporte Raspadita',
      items: [
        {
          title: 'Reportes Raspadita',
          path: '/raspadita',
          icon: <BallotIcon fontSize="small" />,
          role: ['presidencia', 'tdp_gerencia'],
          children: [
            {
              title: 'Ganadores',
              path: '/ganadores',
            },
            {
              title: 'Facturación',
              path: '/facturacion-raspadita-gerencia',
            }
          ]
        }
      ]
    },
    {
      title: 'Sherlock',
      items: [
        {
          title: 'Sherlock',
          path: '/mode-holmes',
          icon: <GiSherlockHolmes fontSize="small" />,
          role: ['presidencia']
        }
      ]
    },
    {
      title: 'Conajzar',
      items: [
        // {
        //   title: 'Informe de cuenta del jugador',
        //   path: '/conajzar/estadoJugador',
        //   icon: <AssignmentIndIcon fontSize="small" />,
        //   role: ['conajzar']
        // },
        {
          title: 'Reporte Financiero',
          path: '/conajzar/reporteFinanciero',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Venta por agencias',
              path: '/ventaAgencias',
            },
            {
              title: 'Medios electrónicos',
              path: '/mediosElectronicos',
            },
            {
              title: 'Terminales',
              path: '/mediosTerminales'
            },
          ]
        },
        {
          title: 'Reportes del sistema',
          path: '/conajzar/reporteSistema',
          icon: <UserIcon fontSize="small" />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Agencia',
          path: '/conajzar/reporteAgencia',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de facturación Diaria',
          path: '/reporteFacturacionDiaria',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de eventos significativos',
          path: '/reporteDeEventos',
          icon: <UsersIcon fontSize="small" />,
          role: ['conajzar'],
          children: [
            {
              title: 'Intentos de login fallidos',
              path: '/loginFallidos',
            },
            {
              title: 'Uptime del sistema',
              path: '/uptimeSistema',
            },
            {
              title: 'Anulaciones',
              path: '/anulacionesEventos'
            },
            {
              title: 'Update de software',
              path: '/softwareUpdate'
            },
            {
              title: 'Logs de parametros del sistema',
              path: '/parametrosSistema'
            },
            {
              title: 'Hearbeats',
              path: '/hearbeatsEventos'
            },
            {
              title: 'Registro de asistencias',
              path: '/registroAsistencia'
            },
            {
              title: 'Auditoria de Firewall',
              path: '/auditoriaFirewall'
            },
            {
              title: 'Reporte de acceso remoto',
              path: '/accesoRemoto'
            },
            {
              title: 'Apuestas Abortadas',
              path: '/apuestasAbortadas'
            },
            {
              title: 'Cambios de posibilidades Comision/Porcentaje',
              path: '/cambiosPosibilidades'
            },
            {
              title: 'Errores de incompatibilidad',
              path: '/erroresIncompatibildad'
            },
          ]
        },
        {
          title: 'Reporte de Pago del premio',
          path: '/conajzar/reportePagoPremios',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de premios superiores',
          path: '/conajzar/reportesPagoSuperior',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['conajzar']
        },
        {
          title: 'Reporte de sorteos',
          path: '/informeLiquidaciones',
          icon: <BallotIcon fontSize='small' />,
          role: ['conajzar']
        },
      ]
    },
    {
      title: 'Agencias',
      items: [
        {
          title: 'Gestionar',
          path: `/`,
          className: 'menu-icon',
          icon: <BriefcaseIcon fontSize="small" />,
          role: ['tesoreria']
        },
      ]
    },
    {
      title: 'Agencias',
      items: [
        {
          title: 'Gestionar',
          path: `/`,
          className: 'menu-icon',
          icon: <BriefcaseIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        },
        // TODO: planilla Agencias
        // {
        //   title: 'Planilla Agencias',
        //   path: `/planillaAgencias`,
        //   className: 'menu-icon',
        //   icon: <BriefcaseIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        // },
        // {
        //   title: 'Planilla',
        //   path: `/planilla`,
        //   icon: <DocumentText fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        // },
        {
          title: 'Planilla Premiados',
          path: `/premiados/0`,
          icon: <AccountBalanceWalletIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        },
        // {
        //   title: 'Planilla Jugadas',
        //   path: `/jugadas`,
        //   icon: <CasinoIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        // },
        {
          title: 'Planilla Cobros',
          path: `/cobros`,
          icon: <ReceiptIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
        },
        {
          title: 'Planilla Cobros Prueba',
          path: `/cobros2`,
          icon: <ReceiptIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
        },
        {
          title: 'Deposito Bancario',
          path: `/depositosAgencia`,
          icon: <AccountBalanceIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
        },
        {
          title: 'Lista de Usuarios',
          path: '/users',
          icon: <UserIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia']
        },
        {
          title: 'Usuarios Externos',
          path: '/usuariosExternos',
          icon: <RecentActorsIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'tdp_operativa']
        },
        {
          title: 'Lista de Dispositivos',
          path: '/devices',
          icon: <Phone fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'agencia']
        },
        {
          title: 'Deposito UMN',
          path: '/depositoUmn',
          icon: <ArchiveIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia']
        },
        // {
        //   title: 'Extracto UMN',
        //   path: '/extractoUmn',
        //   icon: <AssignmentIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn']
        // },
        {
          title: 'Boletas',
          path: '/boletas',
          icon: <TheatersIcon fontSize="small" />,
          role: ['admin_juego', 'tdp_operativa']
        },
      ]
    },
    {
      title: 'Stock',
      items: [
        {
          title: 'Terminales',
          path: `/stock/terminal`,
          className: 'menu-icon',
          icon: <SmartphoneIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'terminal']
        },
      ]
    },
    {
      title: 'Cobrador',
      items: [
        {
          title: 'Entregas',
          path: '/entregas',
          icon: <ShoppingBagIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'agencia', 'umn']
        },
        {
          title: 'Estados Corredor',
          path: '/estadosCorredor',
          icon: <AssignmentIndIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
        },
        {
          title: 'Cuenta',
          path: '/profile',
          icon: <UserIcon fontSize="small" />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Tickets',
          path: '/tickets',
          icon: <ConfirmationNumberIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        },
        {
          title: 'Billetaje',
          path: '/billetaje',
          icon: <DirectionsBusIcon fontSize='small' />,
          role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
        }
      ]
    },
    {
      title: 'Operativa',
      items: [
        {
          title: 'Quiniela',
          path: '/SorteosBase',
          icon: <UsersIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas' ,'umn', 'agencia'],
          children: [
            {
              title: 'Anulaciones',
              path: '/anulaciones',
            },
            {
              title: 'Ajustes',
              path: '/ajustes',
            },
            // {
            //   title: 'Ajustes UMN',
            //   path: '/ajustesUmn',
            // },
            {
              title: 'Agenda sorteos',
              path: '/SorteosBase'
            },
            {
              title: 'Sorteos',
              path: '/Sorteos'
            },
            {
              title: 'Número limitados',
              path: '/numerosLimitados'
            }
          ]
        },
        {
          title: 'Raspadita',
          path: '/SorteosBase',
          icon: <UsersIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas' ,'umn', 'agencia'],
          children: [
            // {
            //   title: 'Corregir Premios',
            //   path: 'raspadita/correcion-premios',
            // },
            {
              title: 'Diferencia Premios',
              path: 'raspadita/diferencia-premios',
            }
          ]
        }
      ]
    },
    {
      title: 'Seguimiento Financiero',
      items: [
        {
          title: 'Resumen General',
          path: '/resumenGeneral',
          icon: <BriefcaseIcon fontSize="small" />,
          role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
        },
        // {
        //   title: 'Depositos',
        //   path: '/resumenDepositos',
        //   icon: <ArchiveIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
        // },
        // {
        //   title: 'Ajustes',
        //   path: '/resumenAjustes',
        //   icon: <SettingsIcon fontSize="small" />,
        //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'cobrador', 'umn', 'agencia']
        // },
      ]
    },
    {
      title: 'Raspaditas',
      items: [
        {
          title: 'Raspaditas',
          path: '/raspaditas',
          icon: <BallotIcon fontSize="small" />,
          role: ['agencia', 'umn' ,'admin_juego', 'tdp_operativa'],
          children: [
            {
              title: 'Recepcion de Cajas',
              path: '/raspaditas/cajas',
            },
            {
              title: 'Recepcion de Libros',
              path: '/raspaditas/libros',
            },
            {
              title: 'Asignacion a Corredores',
              path: '/raspaditas/corredores'
            },
            {
              title: 'Premios',
              path: '/raspaditas/premios'
            },
            {
              title: 'Stock',
              path: '/raspaditas/stock'
            },
            {
              title: 'Boletas',
              path: '/raspaditas',
              children: [
                {
                  title: 'Asignadas a corredores',
                  path: '/raspaditas/boletasAsignadasACorredores'
                },
                {
                  title: 'Asignadas por tipo Juego',
                  path: '/raspaditas/boletasAsignadasCorredores'
                },
                {
                  title: 'Vendidas por corredor',
                  path: '/raspaditas/boletasVendidasCorredores'
                }
              ]
            }
          ]
        },
      ]
    },
    // {
    //   title: 'Raspadita asignacion corredor',
    //   items: [
    //     {
    //       title: 'Asignacion a UMN',
    //       path: `/raspaditas/asignacion-umn`,
    //       className: 'menu-icon',
    //       icon: <BallotIcon fontSize="small" />,
    //       role: ['admin_juego', 'agencia',]
    //     },
    //   ]
    // },
  ];
  }

  const handleChangeUMN = (event) => {
    if(event.target.value === 0 || event.target.value === '0') {
      setUMN(umnS?.map((u) => Number(u.id)));
    } else {
      setUMN(Number(event.target.value));
    }
  };

  const handleChangeZona = (event) => {
    if(event.target.value === 0 || event.target.value === '0'){
      setZona(getZonasByAgencia?.map((u) => Number(u.zona)))

      if (role?.includes('agencia') && !role?.includes('tdp_operativa')) {
        umnS = user.umns
        setUMN(umnS?.map((u) => Number(u.id)));
      }
    } else {
      setZona([Number(event.target.value)]);
      if ((role?.includes('agencia') || role?.includes('finanzas')) || role?.includes('tesoreria') && !role?.includes('tdp_operativa')) {
        umnS = user.umns
        umnS = umnS.filter(umn => umn.zona === parseInt(event.target.value, 10))
        setUMN(umnS?.map((u) => Number(u.id)));
      }
    }
  };

  // useEffect(() => {
  //   // refetch if change zona
  //   refetch({agencia, zona})
  // }, [zona])

  useEffect(() => {
    setZona([0])
  }, [agencia])

  useEffect(() => {
    if(umnS !== undefined){
      if(umnS?.length > 1){
        setUMN(umnS?.map((u) => Number(u.id)));
      } else {
        setUMN(umnS?.map((u) => Number(u.id)))
      }
      // navigate('/')
    }
  }, [umnS])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box>
            <DateConsumer>
              {({actualDate, setDate}) => (
                <Grid container>
                  <List
                    style={{width: '100%', position: 'relative', paddingTop: 20}}
                    subheader={<ListSubheader sx={{ backgroundColor: 'background.default' }}
                    color='inherit'>Filtros</ListSubheader>}>
                    <Grid
                      container
                      sx={{ marginTop: 1}}>
                      <DateAndTimePickers
                        actualDate={actualDate}
                        setDate={setDate} />
                    </Grid>
                    <Grid
                      container
                      justifyContent="space-around"
                    >
                      <Button
                        onClick={() => {
                          const startDate = dayjs(actualDate.startDate).subtract(1, 'day').format('YYYY-MM-DD');
                          const endDate = dayjs(actualDate.endDate).subtract(1, 'day').format('YYYY-MM-DD');
                          setDate({startDate, endDate })
                        }}
                      >
                          <IconButton>
                            <ArrowLeft
                                fontSize="small"
                                style={{color: "#D1236C"}}
                            />
                          </IconButton>
                      </Button>
                      <Button
                        onClick={() => {
                          const startDate = dayjs(actualDate.startDate).add(1, 'day').format('YYYY-MM-DD');
                          const endDate = dayjs(actualDate.endDate).add(1, 'day').format('YYYY-MM-DD');
                          setDate({startDate, endDate })
                        }}
                      >
                          <IconButton>
                            <ArrowRight
                                fontSize="small"
                                style={{color: "#D1236C"}}
                            />
                          </IconButton>
                      </Button>
                    </Grid>
                </List>
                </Grid>
              )}
            </DateConsumer>
            {role?.includes('tdp_gerencia') ?
              null
              :
              <Grid container  >
                <List style={{width: '100%', maxWidth: 360, position: 'relative'}}>
                    <Grid container
                      sx={{ marginTop: 1}}>
                    {
                      agenciaNumber?.umn?.zona === 0 && (role.includes('agencia') || role.includes('tdp_operativa')) || role?.includes('presidencia') || role?.includes('umn')
                      ? null:
                      <Grid item>
                        <TextField
                          sx={{ width:175, marginBottom: 2, marginLeft: 3, marginTop: 2}}
                          label="Zonas"
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          name="zona"
                          onChange={handleChangeZona}
                          value={zona}
                        >
                            <option
                              key={0}
                              value={0}
                            >
                              Todas
                            </option>
                          {
                            getZonasByAgencia?.map((item)=> (
                            <option
                              key={item.zona}
                              value={item?.zona}
                            >
                              {`Zona ${item?.zona}`}
                            </option>
                            ))
                          }
                        </TextField>
                      </Grid>
                    }
                    {/* eslint-disable-next-line no-nested-ternary */}
                    { agencia?.length > 1
                      ?
                      <Grid item >
                        <Label
                          color="secondary"
                        >
                          Todas las UMN seleccionadas
                        </Label>
                      </Grid>
                      // eslint-disable-next-line no-nested-ternary
                      : UMN?.length === 1 && agencia?.length === 1 ?
                      <Grid item >
                        <Label
                          color="secondary"
                        >
                          Todas las UMN seleccionadas
                        </Label>
                      </Grid>
                      : agencia?.length === 1 && UMN?.length > 1 ?
                      <TextField
                          sx={{ width:175, marginBottom: 2, marginLeft: 3, marginTop: 2}}
                          label="UMN"
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          name="umn"
                          onChange={handleChangeUMN}
                          value={UMN}
                        >
                            <option
                              key={0}
                              value={0}
                            >
                              Todas
                            </option>
                          {
                            role?.includes('tdp_operativa') || role?.includes('finanzas') || role?.includes('tesoreria') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') || zona?.length > 1 || zona[0] === 0?
                            umnS?.map((item) => (
                            <option
                              key={item.id}
                              value={item?.id}
                            >
                              {item?.numero} - ({item?.nombre})
                            </option>
                            )) :
                            umnS?.filter(umn => umn.zona === zona[0])?.map((item)=> (
                              <option
                                key={item?.id}
                                value={item?.id}
                              >
                                {item?.numero} - ({item?.nombre})
                              </option>
                            ))
                          }
                        </TextField>
                        :
                        <TextField
                          sx={{ width:175, marginBottom: 2, marginLeft: 3, marginTop: 2}}
                          label="UMN"
                          select
                          SelectProps={{ native: true }}
                          variant="outlined"
                          name="umn"
                          onChange={handleChangeUMN}
                          value={UMN}
                        >
                            <option
                              key={0}
                              value={0}
                            >
                              Todas
                            </option>
                          { role?.includes('tdp_operativa') || role?.includes('finanzas') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') || role?.includes('tesoreria')?
                            umnS?.map((item)=> (
                            <option
                              key={item?.id}
                              value={item?.id}
                            >
                              {item?.numero} - ({item?.nombre})
                            </option>
                            )):
                            umnS?.filter(umn => umn.zona === zona[0])?.map((item)=> (
                              <option
                                key={item?.id}
                                value={item?.id}
                              >
                                {item?.numero} - ({item?.nombre})
                              </option>
                            ))
                          }
                        </TextField>
                    }
                  </Grid>
                </List>
              </Grid>
            }
            {role?.includes('tdp_gerencia') || role?.includes('presidencia') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') ?
            null
            :
            <List style={{width: '100%', position: 'relative'}}>
              <Grid container
                sx={{ marginTop: 1}}>
                <FilterGerencia agencia/>
              </Grid>
            </List>
            }
            {role?.includes('finanzas') || role?.includes('tesoreria') || role?.includes('admin_juego') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') ?
            <List style={{width: '100%', position: 'relative'}}>
              <Grid container
                sx={{ marginTop: 1}}>
                <AgenciesFilter dataAgencia={allAgencies}/>
              </Grid>
            </List>
            : null}
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              '& + &': {
                mt: 3
              }
            }}
            {...section}
          />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
