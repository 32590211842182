/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Gerencia from 'src/pages/dashboard/Gerencia';
import Profile from 'src/pages/dashboard/Profile'
import Entregas from 'src/pages/dashboard/Entregas'
import ReportesDelSistema from 'src/pages/dashboard/conajzar/ReportesDelSistema'
import useAuth from '../hooks/useAuth';
import Login from '../pages/authentication/Login';
import AuthorizationRequired from "../pages/AuthorizationRequired";
import InformeFacturacionRaspadita from '../pages/dashboard/conajzar/ReporteFacturacionRaspadita'

interface RoleBasedGuardProps {
  children: ReactNode;
  roles:string[];
  rights:string[];
}





const RoleBasedGuard: FC<RoleBasedGuardProps> = (props) => {
  const { children, rights, roles } = props;
  const auth = useAuth();
  const {permisos}=auth.user;

  console.log("permisos del usuario: ", permisos)
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

    console.log("****************  RoleBasedGuard  ****************", auth);
    console.log("check for", rights, roles);


  const testPermisos =  ()=>{

      let pass=false;


      const f =  permisos.filter( i=> roles.includes(i.Role.name) );

      console.log("roles filtrados", f, f.length);

      if (f?.length >= 1) {
        return true
      }
      return false


      for(let i=0; i<f.length; i++){

        console.log("f", f[i]);
      // eslint-disable-next-line no-await-in-loop
 const values =   f[i].Rights.map(val=>val.name);
          // const promises =f[i].Rights.map(val=>val.name);

          // const values = await Promise.all(promises);


          console.log("values", values);

          // eslint-disable-next-line no-await-in-loop
          const matches =  rights.reduce(  (a,b)=>{
            let r;

            console.log("test if values incluye a b", values, b);

             if( values.includes(b)   ){ r=a+1 }
             else r=0;
             return r;
          },0);

          if( matches  >=  rights.length   )pass=true;

      }
      return pass;

  };



  if(!testPermisos())  return <AuthorizationRequired />;







  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }





  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  console.log("initial path name: ", location.pathname)
  if (location.pathname === '/') {
    console.log("permisos: ", permisos)
    const gerente = permisos.filter( i => i.Role.name === 'tdp_gerencia' || i.Role.name === 'presidencia')
    console.log("es gerente: ", gerente)
    if (gerente?.length >= 1) {
      return <Gerencia />
    }
    const raspaditaOperativa = permisos.filter( i => i.Role.name === 'raspa_operativa')
    console.log("permisos: ",permisos)
    console.log("es de raspa_operativa: ", raspaditaOperativa)
    if (raspaditaOperativa?.length >= 1) {
      return <InformeFacturacionRaspadita />
    }
    console.log("permisos: ", permisos)
    const conajzar = permisos.filter( i => i.Role.name === 'conajzar')
    console.log("es conajzar: ", conajzar)
    if (conajzar?.length >= 1) {
      return <ReportesDelSistema />
    }
    const cobrador = permisos.filter( i => i.Role.name === 'cobrador')
    console.log(permisos)
    console.log("es cobrador: ", cobrador)
    if (cobrador?.length >= 1) {
      return <Entregas />
    }
    const finanzas = permisos.filter( i => i.Role.name === 'finanzas')
    console.log(permisos)
    console.log("es de finanzas: ", finanzas)
    if (cobrador?.length >= 1) {
      return <Entregas />
    }
    const raspaCon = permisos.filter( i => i.Role.name === 'conajzar_raspa')
    console.log('es de conajzar raspa', raspaCon)
    if(raspaCon?.length >= 1) {
      return <InformeFacturacionRaspadita />
    }
  }
  return <>{children}</>;
};

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  rights: PropTypes.arrayOf(PropTypes.string).isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RoleBasedGuard;
