import type {FC} from 'react'
import React, { useState, memo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Container, Grid, Box, Checkbox, Typography} from '@material-ui/core';
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    container2: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    textField2: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
  }),
);


interface DateTimePickerI {
  actualDate: any;
  setDate: any;
}

export const DateAndTimePickers: FC<DateTimePickerI> = memo(({actualDate, setDate}) => {

  const { startDate, endDate } = actualDate;
  const classes = useStyles();
  console.log("dates: ", startDate, endDate)
  const [checkRango, setCheckRango] = useState(false)
  const handleChangePremiados = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckRango(e.target.checked)
  }
  const handleStartDate = (e) => {
    e.preventDefault();
    setDate({"startDate": e.target.value, endDate })
    if(!checkRango){
      setDate({"startDate": e.target.value, "endDate": e.target.value })
    }
  }
  const handleEndDate = (e) => {
    e.preventDefault();
    setDate({startDate, "endDate": e.target.value })
  }
  return (
    <Container>
        <Grid
            container
            justifyContent="center"
            spacing={3}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{marginTop: "40px"}}
            md={12}
            xl={12}
            xs={12}
          >

            <Checkbox
              defaultChecked
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              checked={checkRango}
              onChange={handleChangePremiados}
            />

            <Typography
              color="textSecondary"
              variant="subtitle2">
                Elegir un rango
            </Typography>
          </Grid>
          <Grid item>
              <form className={classes.container}
  noValidate>
                  <Box sx={{marginBottom: "20px", marginTop: "0px"}}>
                      <TextField
                          id="datetime-local-inicio"
                          label= {checkRango ? "Desde" : "Fecha"}
                          type="date"
                          defaultValue={startDate}
                          className={classes.textField}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          onChange={handleStartDate}
                          value={startDate}
                      />
                  </Box>
                  {checkRango ?
                    <Box sx={{marginBottom: "20px", marginTop: "0px"}}>
                        <TextField
                            id="datetime-local-inicio"
                            label= "Hasta"
                            type="date"
                            defaultValue={startDate}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleEndDate}
                        />
                    </Box> :
                    <Box />
                  }
              </form>
          </Grid>
        </Grid>
    </Container>
  );
})

DateAndTimePickers.propTypes = {
    actualDate: PropTypes.any,
    setDate: PropTypes.func,
};
