import { useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
	TableHead,
	IconButton,
  TableRow,
	Typography,
  Button,
} from '@material-ui/core';
import dayjs from 'dayjs'
import { Link as RouterLink } from 'react-router-dom'
import formatNumbers from 'src/utils/formatNumbers';
import { useGetUmnsQuery } from '../../../generated/graphql'
import Scrollbar from '../../Scrollbar';
import Export from '../../export/Exports';
import ArrowRight from '../../../icons/ArrowRight';

interface TableDetailConfirmadaProps {
	items?: any[];
		umnSelected?: any;
		setUmnSelected?: any;
		handleSubmit?: any;
    agencia?: any;
}

const TableTransaccionesProducto: FC<TableDetailConfirmadaProps> = (props) => {
    const { items, setUmnSelected, umnSelected, handleSubmit, agencia,  ...other } = props;

    let variableAgencia;
    if (agencia === 0 || agencia === '0') {
      variableAgencia = [1, 2, 3];
    } else {
      variableAgencia = [parseInt(agencia, 10)];
    }

    const { data } = useGetUmnsQuery({variables: {agencia: variableAgencia}});

    const dataUmn = []
		data?.allUmns.map(oneUmn => dataUmn.push({id: oneUmn.id, numero: oneUmn.numero }))

		const isAllSelected = dataUmn.length > 0 && umnSelected.length === dataUmn.length;

		useEffect(() => {}, [umnSelected, isAllSelected])

    useEffect(() => {}, [items, agencia])

		const totales = {cantidad: 0, comisionA: 0, comisionC:0, neto: 0, bruto: 0, cpremiados : 0, premio : 0}

    const headers = ["Producto", "Cantidad", "Total Bruto", "Premiados", "Premios", "Comision A", "Comision C", "Neto"];

    let dataPdf = items.map((item)=> [
      item.nombre,
      formatNumbers(parseInt(item.cantidad, 10)),
      formatNumbers(parseInt(item.total, 10)),
      formatNumbers(parseInt(item?.cantidad_premiados ?? 0, 10)),
      formatNumbers(parseInt(item?.premio ?? 0, 10)),
      formatNumbers(parseInt(item.totalComisionA, 10)),
      formatNumbers(parseInt(item.totalComisionC, 10)),
      formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))
    ]);
    // eslint-disable-next-line array-callback-return
    items.map((item) => {
      totales.bruto += parseInt(item.total, 10)
      totales.cantidad += parseInt(item.cantidad, 10)
      totales.comisionA += parseInt(item.totalComisionA, 10)
      totales.comisionC += parseInt(item.totalComisionC, 10)
      totales.cpremiados += parseInt(item?.cantidad_premiados ?? 0, 10)
      totales.premio += parseInt(item?.premio ?? 0, 10)
      totales.neto += (parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio ?? 0, 10))
    })
    dataPdf = [
      ...dataPdf,
      [
        "Total: ",
        formatNumbers(totales.cantidad),
        formatNumbers(totales.bruto),
        formatNumbers(totales.cpremiados),
        formatNumbers(totales.premio),
        `${formatNumbers(totales.comisionA)}`,
        `${formatNumbers(totales.comisionC)}`,
        `${ formatNumbers(totales.neto)}`
      ]
    ]

    const pdfData = {
      fontSize: 10,
      title: "Transacciones por Producto",
      headers,
      data: dataPdf,
      downloadTitle: `productos-${dayjs().format('DD-MM-YYYY')}`,
    }

    return (
    <>
        <Card {...other}>
          <CardHeader
            action={<Export pdf
            pdfData={pdfData} />}
            title="Transacciones por Producto"
          />
            <Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                              <TableCell>
																<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Producto
																</Typography>
                              </TableCell>
                              <TableCell align="right">
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
                                	Cantidad
																</Typography>
                              </TableCell>
                              <TableCell  align="right">
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
	                                Total Bruto
																</Typography>
                              </TableCell>
                              <TableCell  align="right">
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Cantidad Premiados
																</Typography>
                              </TableCell>
                              <TableCell  align="right">
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Total Premios
																</Typography>
                              </TableCell>
                              <TableCell  align="right">
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Total Comisión Agencia
																</Typography>
                              </TableCell>
															<TableCell  align="right">
															<Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Total Comisión Corredor

																</Typography>
                              </TableCell>
															<TableCell  align="right">
														    <Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Neto
																</Typography>
                              </TableCell>
                              <TableCell  align="right">
														    <Typography
																	color="textPrimary"
																	variant="h6"
																>
																	Opciones
																</Typography>
                              </TableCell>
                              <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((item) => {
                             const productType = item.nombre === 'Quiniela';

														 return <TableRow
                                hover
                                key={item.id}
                                >
                                    <TableCell>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
																					{formatNumbers(parseInt(item.cantidad, 10))}
                                        </Typography>
                                    </TableCell>
																		<TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.total, 10))}
                                        </Typography>
                                    </TableCell>
                                    { productType
                                      ?
                                      <>
                                        <TableCell align="right">
                                          <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                          >
                                            {formatNumbers(parseInt(item?.cantidad_premiados, 10))}
                                            {/* - */}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                        <Typography
                                          color="textPrimary"
                                          variant="subtitle1"
                                        >
                                          {formatNumbers(parseInt(item?.premio, 10))}
                                          {/* - */}
                                        </Typography>
                                      </TableCell>
                                      </>
                                      :
                                      <>
                                        <TableCell align="right">
                                          <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                          >
                                           {formatNumbers(parseInt(item?.cantidad_premiados || 0, 10))}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                        <Typography
                                          color="textPrimary"
                                          variant="subtitle1"
                                        >
                                           {formatNumbers(parseInt(item?.premio || 0, 10))}
                                        </Typography>
                                      </TableCell>
                                      </>
                                    }
																		<TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.totalComisionA, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell  align="right">
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                        >
                                            {formatNumbers(parseInt(item.totalComisionC, 10))}
                                        </Typography>
                                    </TableCell>
                                    {
                                      // eslint-disable-next-line no-nested-ternary
                                      productType ?
                                      <>
                                        <TableCell  align="right">
                                          <Typography
                                              color="textPrimary"
                                              variant="subtitle1"
                                          >
                                              { formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio, 10)) }
                                          </Typography>
                                        </TableCell>
                                        <TableCell/>
                                      </>
                                    :
                                    item?.nombre === "Raspadita" ?
                                      <>
                                          <TableCell  align="right">
                                            <Typography
                                                color="textPrimary"
                                                variant="subtitle1"
                                            >
                                                { formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10) - parseInt(item?.premio, 10)) }
                                            </Typography>
                                          </TableCell>
                                          <TableCell/>
                                        </>
                                      :
                                      <>
                                      <TableCell  align="right">
                                          <Typography
                                              color="textPrimary"
                                              variant="subtitle1"
                                          >
                                              { formatNumbers(parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10)) }
                                          </Typography>
                                      </TableCell>
                                      {item?.nombre === "Raspadita" ?
                                        <TableCell align='right'>
                                        <Button
                                            component={RouterLink}
                                            to="/planillaGeneral/gerencia/raspadita/totales"
                                            sx={{maxHeight:10}}
                                        >
                                            <IconButton>
                                              <ArrowRight
                                                  fontSize="small"
                                                  style={{color: "#D1236C"}}
                                              />
                                            </IconButton>
                                        </Button>
                                        </TableCell>: <TableCell/>
                                      }
                                      </>
                                    }
                                    <TableCell/>
                                  </TableRow>
                              })}
														<TableRow>
                                <TableCell>
                                    <Typography
                                        color="textSecondary"
                                        variant="h6"
                                    >
                                       Total
                                    </Typography>
                                </TableCell>
                                <TableCell  align="right">
																	<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.cantidad)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.bruto)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                     color="textPrimary"
                                     sx={{fontWeight:"bold"}}
                                  >
                                   {formatNumbers(totales?.cpremiados)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                     color="textPrimary"
                                     sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales?.premio)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.comisionA)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.comisionC)}
                                  </Typography>
                                </TableCell>
                                <TableCell  align="right">
																<Typography
                                    color="textPrimary"
                                    sx={{fontWeight:"bold"}}
                                  >
                                    {formatNumbers(totales.neto)}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                      component={RouterLink}
                                      to="/gerencia/productos/"
                                      sx={{maxHeight:10}}
                                  >
                                      <IconButton>
                                        <ArrowRight
                                            fontSize="small"
                                            style={{color: "#D1236C"}}
                                        />
                                      </IconButton>
                                  </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
        </Card>
    </>)
};

TableTransaccionesProducto.propTypes = {
		items: PropTypes.any.isRequired,
		umnSelected: PropTypes.any,
		setUmnSelected: PropTypes.any,
		handleSubmit: PropTypes.any,
    agencia: PropTypes.any,
};

export default TableTransaccionesProducto;
