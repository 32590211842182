import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';

import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import RoleBasedGuard from "./components/RoleBasedGuard";

import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import PlanillaGeneralAgencia from './pages/dashboard/gerencia/productos/PlanillaGeneralAgencia';


const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);



// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));


// Dashboard pages
const SorteoDetails = Loadable(lazy(() => import('./pages/dashboard/SorteoDetails')));
const SorteoDetailsPendientes = Loadable(lazy(() => import('./pages/dashboard/SorteoDetailsPendientes')));

// Gerencia
const Gerencia = Loadable(lazy(() => import('./pages/dashboard/Gerencia')))
const GerenciaAgencias = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgencias')))
const GerenciaAgenciasProducto = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasProducto')))
const GerenciaAgenciasUmnProducto = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasUmnProducto')))
const GerenciaAgenciasProductoCorredor = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasProductoCorredor')))
const GerenciaAgenciasUmnProductoCorredor = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasUmnProductoCorredor')))
const GerenciaAgenciasCorredorProductos = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasCorredorProductos')))
const GerenciaAgenciasUmn = Loadable(lazy(() => import('./pages/dashboard/GerenciaAgenciasUmns')))
const GerenciaDetailticket = Loadable(lazy(() => import('./pages/dashboard/GerenciaDetailTickets')))
const PlanillaPremiadosSorteoGerencia = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosSorteoGerencia')));
const PremiadosGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/premios/GerenciaPremios')))
// Agencia
const PlanillaGeneral = Loadable(lazy(() => import('./pages/dashboard/PlanillaGeneral')));
const PlanillaAgencias = Loadable(lazy(() => import('./pages/dashboard/PlanillaAgencias')));
const Planilla = Loadable(lazy(() => import('./pages/dashboard/Planilla')));
const PlanillaPremiados = Loadable(lazy(() => import('./pages/dashboard/Premiados')));
const Boletas = Loadable(lazy(() => import('./pages/dashboard/Boletas')));
const PlanillaPremiadosGerencia = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosGerencia')));
const PlanillaPremiadosGerenciaUmn = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosGerenciaUmn')));
const PlanillaPremiadosCorredorGerencia = Loadable(lazy(() => import('./pages/dashboard/PlanillaPremiadosCorredorGerencia')));
const SorteosBase = Loadable(lazy(() => import('./pages/dashboard/SorteosBase')));
const Sorteos = Loadable(lazy(() => import('./pages/dashboard/Sorteos')));
const RaspaditaPremios = Loadable(lazy(() => import('./pages/dashboard/RaspaditaPremios')));
const PlanillaAsignadasPorCorredor = Loadable(lazy(() => import('./pages/dashboard/PlanillaAsignadasPorCorredor')));
const PlanillaAsignadasPorCorredorAgencias = Loadable(lazy(() => import('./pages/dashboard/PlanillaAsignadasPorCorredorAgencias')));
const PlanillaAsignadasPorCorredorByCorredor = Loadable(lazy(() => import('./pages/dashboard/PlanillaAsignadasPorCorredorByCorredor')));
const PlanillaVentasPorCorredor = Loadable(lazy(() => import('./pages/dashboard/PlanillaVentasPorCorredor')));
const PlanillaVentasPorCorredorAgencias = Loadable(lazy(() => import('./pages/dashboard/PlanillaVentasPorCorredorAgencias')));
const PlanillaVentasPorCorredorByCorredor = Loadable(lazy(() => import('./pages/dashboard/PlanillaVentasPorCorredorByCorredor')));
const Limitados = Loadable(lazy(() => import('./pages/dashboard/Limitados')));
const CorredoresDataList = Loadable(lazy(() => import('./pages/dashboard/CorredorDataList')));
const CorredoresDatailsFecha = Loadable(lazy(() => import('./pages/dashboard/CorredorDetailsFecha')));
const UserList = Loadable(lazy(() => import('./pages/dashboard/UsersList')));
const DeviceList = Loadable(lazy(() => import('./pages/dashboard/DeviceList')));
const TotalTerminales = Loadable(lazy(() => import('./pages/dashboard/TotalTerminales')));
const DeviceDetails = Loadable(lazy(() => import('./pages/dashboard/DeviceDetails')));
const Entregas = Loadable(lazy(() => import('./pages/dashboard/Entregas')));
const CajasAbiertas = Loadable(lazy(() => import('./pages/dashboard/CajasAbiertas')));
const Anulaciones = Loadable(lazy(() => import('./pages/dashboard/Anulaciones')));
const Profile = Loadable(lazy(() => import('./pages/dashboard/Profile')));
const AgenciesPlanillaGeneral = Loadable(lazy(() => import('./pages/dashboard/AgenciesPlanillaGeneral')))
const TerminalSerial = Loadable(lazy(() => import('./pages/dashboard/TerminalSerial')))
// const ProfileDetailsFecha = Loadable(lazy(() => import('./pages/dashboard/ProfileDetailsFecha')));
const ProfileCobrador = Loadable(lazy(() => import('./pages/dashboard/ProfileCobrador')));
const PlanillaGeneralEntregaCobrador = Loadable(lazy(() => import('./pages/dashboard/PlanillaGeneralEntregaCorredor')))
// Agencia
const EstadosCorredor = Loadable(lazy(() => import('./pages/dashboard/EstadosCorredor')))
const Tickets = Loadable(lazy(() => import('./pages/dashboard/Tickets')))
const Billetaje = Loadable(lazy(() => import('./pages/dashboard/Billetaje')))
const BilletajeGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/productos/BilletajeGerencia')))
const PlanillaGerenciaAgencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/productos/PlanillaGeneralAgencia')))
const PlanillaGerenciaUmn = Loadable(lazy(() => import('./pages/dashboard/gerencia/productos/PlanillaGeneralUmn')))
const PlanillaTotalStockJuego = Loadable(lazy(() => import('./pages/dashboard/raspa/PlanillaTotalStockJuego')))
const PlanillaGerenciaRaspadita = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaTotalJuego')))
const Jugadas = Loadable(lazy(() => import('./pages/dashboard/Jugadas')))
const PlanillaCobros = Loadable(lazy(() => import('./pages/dashboard/PlanillaCobros')))
const PlanillaCobros2 = Loadable(lazy(() => import('./pages/dashboard/PlanillaCobros2')))
const PlanillaProducto = Loadable(lazy(() => import('./pages/dashboard/PlanillaProducto')))
const PlanillaProductoByUmn = Loadable(lazy(() => import('./pages/dashboard/PlanillaProductoByUmn')))
const PlanillaRaspaditaByAgencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/PlanillaRaspaditaByAgencia')));
const TicketsCorredor = Loadable(lazy(() => import('./pages/dashboard/TicketsCorredor')));
const DetallesProductoCorredor = Loadable(lazy(() => import('./pages/dashboard/DetallesProductoCorredor')));
const UsuariosExternos = Loadable(lazy(() => import('./pages/dashboard/corredores/UsuariosExternos')));
// Raspadita
const RaspaditasCajas = Loadable(lazy(() => import('./pages/dashboard/RaspaditasCajas')))
const RaspaditasPremios = Loadable(lazy(() => import('./pages/dashboard/RaspaditasPremios')))
const RaspaditasLibros = Loadable(lazy(() => import('./pages/dashboard/RaspaditaLibros')))
const RaspaditasStock = Loadable(lazy(() => import('./pages/dashboard/RaspaditasStock')))
const RaspaditaCorredoresBoletasAsignadas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaCorredoresBoletasAsignadas')))
const RaspaditaCorredoresBoletasAsignadasLista = Loadable(lazy(() => import('./pages/dashboard/RaspaditaCorredoresBoletasAsignadasLista')))
const RaspaditaCorredoresBoletasAsignadasGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/RaspaditaCorredoresBoletasAsignadasGerencia')))
const RaspaditaCorredoresBoletasVendidas = Loadable(lazy(() => import('./pages/dashboard/RaspaditaCorredoresBoletasVendidas')))
const RaspaditaDifenciaPremios = Loadable(lazy(() => import('./pages/dashboard/RaspaditaDiferenciaPremios')));
const RaspaditasCorredor = Loadable(lazy(() => import('./pages/dashboard/RaspaditasCorredor')))
const RaspaditaUMN = Loadable(lazy(() => import('./pages/dashboard/RaspaditaUMN')))
const RaspaditasDetallesCaja = Loadable(lazy(() => import('./pages/dashboard/RaspaditasDetallesCaja')))
const RaspaditaDetallesLibro = Loadable(lazy(() => import('./pages/dashboard/RaspaditaDetallesLibro')))
const StockPallets = Loadable(lazy(() => import('./pages/dashboard/raspa/StockPallets')))
const StockCajas = Loadable(lazy(() => import('./pages/dashboard/raspa/StockCajas')))
const StockLibros = Loadable(lazy(() => import('./pages/dashboard/raspa/StockLibros')))
const StockBoletas = Loadable(lazy(() => import('./pages/dashboard/raspa/StockBoletas')))
const PlanillaTotalStockJuegoDias = Loadable(lazy(() => import('./pages/dashboard/raspa/PlanillaTotalStockJuegoDias')))
const StockRaspaditaGerencia = Loadable(lazy(() => import('./pages/dashboard/raspa/StockGerencia')));
// Depositos
const Depositos = Loadable(lazy(() => import('./pages/dashboard/depositos/Depositos')))
// Stock
const StockTerminal = Loadable(lazy(() => import('./pages/dashboard/stock/StockTerminal')))
const HistorialTerminales = Loadable(lazy(() => import('./pages/dashboard/stock/HistorialTerminales')))
// Pago de premios
const PagoPremios = Loadable(lazy(() => import('./pages/dashboard/premios/PremiosPagados')))
// Finanzas
const FinanzasResumenGeneral = Loadable(lazy(() => import('./pages/dashboard/FinanzasResumenGeneral')));
const FinanzasGerencia = Loadable(lazy(() => import('./pages/dashboard/FinanzasGerencia')));
const FinanzasGerenciaDetalle = Loadable(lazy(() => import('./pages/dashboard/FinanzasGerenciaDetalle')));
// const FinanzasResumenAjustes = Loadable(lazy(() => import('./pages/dashboard/FinanzasResumenAjustes')));
// const FinanzasResumenDepositos = Loadable(lazy(() => import('./pages/dashboard/FinanzasResumenDepositos')));
// Quinela
const Ajustes = Loadable(lazy(() => import('./pages/dashboard/Ajustes')))
const AjustesUmn = Loadable(lazy(() => import('./pages/dashboard/AjustesUmn')))
// Extractos UMN
const Deposito = Loadable(lazy(() => import('./pages/dashboard/Deposito')));
const ExtractoUMN = Loadable(lazy(() => import('./pages/dashboard/ExtractoUMN')));
// Conajzar
const InformeCuentaJugador = Loadable(lazy(() => import('./pages/dashboard/conajzar/InformeCuentaJugador')));
const ReporteFacturacionAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteFacturacionAgencia')));
const ReportePagoDePremios = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePagoDePremios')));
const ReportePagoPremiosSuperiores = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePagoPremiosSuperiores')));
const InformeLiquidaciones = Loadable(lazy(() => import('./pages/dashboard/conajzar/InformeLiquidaciones')));
const InformeFacturacionRaspadita = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteFacturacionRaspadita')));
const InformeFacturacionRaspaditaGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/ReporteFacturacionRaspadita')));
const InformePremiadosRaspadita = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePremiadosRaspadita')));
const InformeLibrosVendidosRaspadita = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteLibrosVendidosRaspadita')));
const InformePremiadosRaspaditaGerencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportePremiadosRaspaditaGerencia')));
const GanadoresPorAgenciaGerencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/GanadoresPorAgenciaGerencia')));
const VentasPorAgenciaGerencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorAgenciaGerencia')));
const GanadoresPorCorredorAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/GanadoresPorCorredorAgencia')));
const VentasPorCorredorAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorCorredorAgencia')));
const ReportesDelSistema = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReportesDelSistema')));
const VentasPorAgencia = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorAgencia')));
const VentasPorMediosElectronicos = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorMediosElectronicos')));
const VentasPorTerminal = Loadable(lazy(() => import('./pages/dashboard/conajzar/VentasPorTerminal')));
const AnulacionesConajzar = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/Anulaciones')));
const CambiosParametrosSistema = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/CambiosParametrosSistema')));
const Heartbeat = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/Heartbeat')));
const IntentosLogin = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/IntentosLogin')));
const RegistroDeAsistencias = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/RegistroDeAsistencias')));
const UpdateSistema = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/UpdateSistema')));
const UpdateSoftware = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/UpdateSoftware')));
const PremiosPagados = Loadable(lazy(() => import('./pages/dashboard/premios/PremiosPagados')));
const AuditoriaFirewall = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/AuditoriaFirewall')));
const AccesoRemoto = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/AccesoRemoto')));
const AbortoApuestas = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/AbortoApuestas')));
const CambiosPosibilidades = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/CambiosPosibilidades')));
const ErrorIncompatibilidad = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/ErrorIncompatibilidad')));
const EstacionDeApuestas = Loadable(lazy(() => import('./pages/dashboard/conajzar/eventos/EstacionDeApuestas')));
const ReporteFacturacionDiaria = Loadable(lazy(() => import('./pages/dashboard/conajzar/ReporteFacturacion')));
// Gerencia
const Sherlock = Loadable(lazy(() => import('./pages/dashboard/gerencia/Sherlock')));
const BoletasPorMonto = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/BoletasPorMonto')));
const HistorialDeMontos = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/HistorialDeSorteo')));
const Ocurrencias = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/Ocurrencias')));
const TombolaOcurrencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/TombolaOcurrencias')));
const Bolillero = Loadable(lazy(() => import('./pages/dashboard/gerencia/reportes/Bolillero')));
const AsignacionesGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/AsignacionesGerencia')));
// const StockRaspaditaGerencia = Loadable(lazy(() => import('./pages/dashboard/gerencia/raspadita/StockGerencia')));
// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));
const Proyeccion = Loadable(lazy(() => import('./pages/dashboard/proyecciones')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: '/s/:id',
    element: <SorteoDetails />
  },
  {
    path: '*',   // ex dashboard
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element:<RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'presidencia', 'tesoreria', 'conajzar', 'raspa_operativa', 'conajzar_raspa']}
        rights={[ 'write', 'read', 'edit']} > <PlanillaGeneral /> </RoleBasedGuard>
      },
      {
        path: '/:id',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
        rights={[ 'write', 'read', 'edit']} > <PlanillaGeneral /> </RoleBasedGuard>
      },
      {
        path: 'profile',
        element: <RoleBasedGuard  roles={['cobrador']}
          rights={[ 'read']} > <Profile /> </RoleBasedGuard>
      },
      {
        path: '/profile/:idCobrador/fecha/:corredorFecha',
        element: <PlanillaGeneralEntregaCobrador />
      },
      {
        path: 'sorteosBase',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia' ,'umn', 'agencia']}
        rights={[ 'write', 'read', 'edit']} > <SorteosBase /> </RoleBasedGuard>
      },
      {
        path: 'ajustes',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia' ,'umn', 'agencia']}
        rights={[ 'write', 'read', 'edit']} > <Ajustes /> </RoleBasedGuard>
      },
      {
        path: 'planillaAgencias',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia' ,'umn', 'agencia', 'tesoreria']}
        rights={[ 'write', 'read', 'edit']} > <PlanillaAgencias /> </RoleBasedGuard>
      },
      {
        path: 'ajustesUmn',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'agencia']}
        rights={[ 'write', 'read', 'edit']} > <AjustesUmn /> </RoleBasedGuard>
      },
      {
        path: 'planillaGeneral/:id',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
          rights={[ 'write', 'read', 'edit']} > <PlanillaGeneral /> </RoleBasedGuard>
      },
      {
        path: '/cajasAbiertas/',
        element: <CajasAbiertas />
      },
      {
        path: 'planillaGeneral',
        children: [
          {
            path: '/producto/:idProducto/:umnNumber',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'presidencia', 'agencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><PlanillaProductoByUmn /></RoleBasedGuard>
          },
          {
            path: '/producto',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><PlanillaProducto /></RoleBasedGuard>
          },
          {
            path: '/producto/:idProducto/corredor/:idCorredor/perfil/:idPerfil',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'presidencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><TicketsCorredor /></RoleBasedGuard>
          },
          {
            path: '/productos/:idProducto/corredor/:idCorredor/perfil/:idPerfil',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'presidencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><DetallesProductoCorredor /></RoleBasedGuard>
          },
          {
            path: '/productos/:idProducto/corredor/:idCorredor/perfil/:idPerfil/:idJuego',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'presidencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><DetallesProductoCorredor /></RoleBasedGuard>
          },
          {
            path: '/totalProductos/:idProducto',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><PlanillaCobros /></RoleBasedGuard>
          },
          {
            path: '/corredor/:idCorredor',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><CorredoresDataList /></RoleBasedGuard>
          },
          {
            path: '/cobrador/:idCobrador',
            element: <ProfileCobrador />
          },
          {
            path: '/corredor/:idCorredor/fecha/:corredorFecha',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} > <CorredoresDatailsFecha/> </RoleBasedGuard>
          },
          {
            path: '/agencias/producto/:idProducto',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} > <AgenciesPlanillaGeneral/> </RoleBasedGuard>
          },
          {
            path: '/gerencia/agencias/umn/producto/:idProducto/:agenciaNumero',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia']}
            rights={[ 'write', 'read', 'edit']} > <PlanillaGerenciaUmn/> </RoleBasedGuard>
          },
          {
            path: '/producto/gerencia/:idProducto/:umnNumber/:idJuego',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'presidencia', 'agencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><PlanillaProductoByUmn /></RoleBasedGuard>
          },
          {
            path: '/gerencia/agencias/producto/:idProducto',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} > <PlanillaGerenciaAgencia/> </RoleBasedGuard>
          },
          {
            path: '/gerencia/agencias/producto/:idProducto/all',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia']}
            rights={[ 'write', 'read', 'edit']} > <PlanillaGerenciaUmn/> </RoleBasedGuard>
          },
          {
            path: '/gerencia/raspadita/totales',
            element: <RoleBasedGuard roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'write', 'read', 'edit']} > <PlanillaGerenciaRaspadita/> </RoleBasedGuard>
          },
          {
            path: '/gerencia/agencias/producto/:idProducto/:juegoId',
            element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'presidencia', 'umn', 'agencia', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} > <PlanillaGeneralAgencia/> </RoleBasedGuard>
          },
          {
            path: '/producto/gerencia/:juegoId/:umnNumber',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'presidencia', 'agencia', 'cobrador', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} ><PlanillaRaspaditaByAgencia /></RoleBasedGuard>
          },
        ]
      },
      {
        path: 'pagarPremios',
        element: <RoleBasedGuard roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
        rights={[ 'write', 'read', 'edit']}> <PremiosPagados /> </RoleBasedGuard>
      },
      {
        path: 'entregas',
        element: <Entregas />
      },
      {
        path: 'depositoUmn',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} > <Deposito /> </RoleBasedGuard>
      },
      {
        path: 'extractoUmn',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn', 'tesoreria']}
            rights={[ 'write', 'read', 'edit']} > <ExtractoUMN /> </RoleBasedGuard>
      },
      {
        path: 'tickets',
        element: <Tickets />
      },
      {
        path: 'billetaje',
        element: <Billetaje />
      },
      {
        path: 'boletas',
        element: <Boletas />
      },
      {
        path: 'estadosCorredor',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']}
          rights={[ 'read']} > <EstadosCorredor /> </RoleBasedGuard>
      },
      {
        path: 'terminal/:serialTerminal/:corredorNumero/:idCorredor/:cajaId',
        element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
          rights={['read']} > <TerminalSerial /> </RoleBasedGuard>
      },
      {
        path: 'planilla',
        element: <Planilla />
      },
      {
        path: 'premiados/:idCorredor',
        element: <PlanillaPremiados />
      },
      {
        path: 'jugadas',
        element: <Jugadas />
      },
      {
        path: 'cobros',
        element: <PlanillaCobros />
      },
      {
        path: 'cobros2',
        element: <PlanillaCobros2 />
      },
      {
        path: 'anulaciones',
        element: <Anulaciones />
      },
      {
        path: 'stock',
        children: [
          {
            path: 'terminal',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'tecnico']}
            rights={[ 'write', 'read', 'edit' ]}> <StockTerminal /> </RoleBasedGuard>
          },
          {
            path: 'historialTerminal/:idTerminal',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'tecnico']}
            rights={[ 'write', 'read', 'edit' ]}> <HistorialTerminales /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: 'raspaditas',
        children: [
          {
            path: 'cajas',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditasCajas /> </RoleBasedGuard>
          },
          {
            path: 'libros',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditasLibros /> </RoleBasedGuard>
          },
          {
            path: 'stock',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditasStock /> </RoleBasedGuard>
          },
          {
            path: 'asignacion-umn',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditaUMN /> </RoleBasedGuard>
          },
          {
            path: 'recepcion-caja',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditasCajas /> </RoleBasedGuard>
          },
          {
            path: 'corredores',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditasCorredor /> </RoleBasedGuard>
          },
          {
            path: 'premios',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas', 'raspa_operativa']}
            rights={['write', 'read', 'edit']} > <RaspaditasPremios /> </RoleBasedGuard>
          },
          {
            path: 'caja/:id/:codigo',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditasDetallesCaja /> </RoleBasedGuard>
          },
          {
            path: 'caja/libro/:id/:codigo',
            element: <RoleBasedGuard  roles={['admin_juego', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditaDetallesLibro /> </RoleBasedGuard>
          },
          {
            path: 'boletasAsignadasCorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
            rights={['read']}> <RaspaditaCorredoresBoletasAsignadas /> </RoleBasedGuard>
          },
          {
            path: 'boletasAsignadasACorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn']}
            rights={['read']}> <RaspaditaCorredoresBoletasAsignadasLista /> </RoleBasedGuard>
          },
          // {
          //   path: 'boletasAsignadasCorredoresGerencia/:agenciaNumero/:idJuego',
          //   element: <RoleBasedGuard roles={['admin_juego', 'presidencia', 'tdp_gerencia', 'finanzas']}
          //   rights={['read']}> <RaspaditaCorredoresBoletasAsignadasGerencia /> </RoleBasedGuard>
          // },
          {
            path: 'boletasVendidasCorredores',
            element: <RoleBasedGuard roles={['admin_juego', 'agencia', 'umn', 'finanzas']}
            rights={['read']}> <RaspaditaCorredoresBoletasVendidas /> </RoleBasedGuard>
          }
        ]

      },
      {
        path: 'totalTerminales',
        element: <TotalTerminales />
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard  roles={['admin_juego', 'tdp_gerencia', 'umn', 'agencia']}
            rights={[ 'write', 'read', 'edit']} > <UserList /> </RoleBasedGuard>
          },
        ]
      },
      {
        path: 'usuariosExternos',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'umn', 'agencia', 'tdp_operativa']}
            rights={[ 'write', 'read', 'edit']} > <UsuariosExternos /> </RoleBasedGuard>
          }
        ],
      },
      {
        path: 'devices',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <DeviceList /> </RoleBasedGuard>
          },
          {
            path:  '/details/:id',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'agencia', 'umn']}
            rights={[ 'write', 'read', 'edit']} > <DeviceDetails /> </RoleBasedGuard>
          },
        ]

      },
      {
        path: 'resumenGeneral',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador', 'tesoreria']}
          rights={[ 'write', 'read', 'edit']} > <FinanzasResumenGeneral /> </RoleBasedGuard>
      },
      {
        path: 'pagoDePremios',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
          rights={[ 'write', 'read', 'edit']} > <PagoPremios /> </RoleBasedGuard>
      },
      {
        path: 'depositosAgencia',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
          rights={[ 'write', 'read', 'edit']} > <Depositos /> </RoleBasedGuard>
      },
      // {
      //   path: 'depositosAgencia',
      //   element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'tesoreria']}
      //     rights={[ 'write', 'read', 'edit']} > <Depositos /> </RoleBasedGuard>
      // },
      // {
      //   path: 'resumenDepositos',
      //   element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']}
      //     rights={[ 'write', 'read', 'edit']} > <FinanzasResumenDepositos /> </RoleBasedGuard>
      // },
      // {
      //   path: 'resumenAjustes',
      //   element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']}
      //     rights={[ 'write', 'read', 'edit']} > <FinanzasResumenAjustes /> </RoleBasedGuard>
      // },
      {
        path: 'numerosLimitados',
        element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia']}
        rights={[ 'write', 'read', 'edit']} > <Limitados /> </RoleBasedGuard>
      },
      {
        path: 'sorteos',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
            rights={[ 'write', 'read', 'edit']} > <Sorteos /> </RoleBasedGuard>
          },
          {
            path: ':id',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
            rights={[ 'write', 'read', 'edit']} > <SorteoDetails /> </RoleBasedGuard>
          },
          {
            path: ':id/pendientes',
            element: <RoleBasedGuard  roles={['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia']}
            rights={[ 'write', 'read', 'edit']} > <SorteoDetailsPendientes /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: 'raspadita',
        children: [
          {
            path: '/correcion-premios',
            element: <RoleBasedGuard  roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditaPremios /> </RoleBasedGuard>
          },
          {
            path: '/diferencia-premios',
            element: <RoleBasedGuard  roles={['admin_juego', 'tdp_operativa', 'raspa_operativa']}
            rights={[ 'write', 'read', 'edit']} > <RaspaditaDifenciaPremios /> </RoleBasedGuard>
          }
        ]
      },
      {
        path: 'gerencia',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <Gerencia /> </RoleBasedGuard>
          },
          {
            path: '/productos/',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaAgenciasProducto /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaAgenciasUmnProducto /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaAgenciasUmnProductoCorredor /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:corredor_id',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaAgenciasCorredorProductos /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:corredor_id/Quiniela',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaDetailticket /> </RoleBasedGuard>
          },
          {
            path: '/productos/umns/:agencia_numero/:umn_id/corredores/:corredor_id/Billetaje',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <BilletajeGerencia /> </RoleBasedGuard>
          },
          {
            path: '/productos/:agencia_numero/corredores',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaAgenciasProductoCorredor /> </RoleBasedGuard>
          },
          {
            path: '/productos/:agencia_numero/corredores/:corredor_id',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaAgenciasCorredorProductos /> </RoleBasedGuard>
          },
          {
            path: '/productos/:agencia_numero/corredores/:corredor_id/Quiniela',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaDetailticket /> </RoleBasedGuard>
          },
          {
            path: '/agencias/:sorteoId',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']} > <GerenciaAgencias /> </RoleBasedGuard>
          },
          {
            path: '/premiados/:sorteoId/:agencia',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia', 'tesoreria']}
            rights={[ 'read']}> <PlanillaPremiadosGerencia /></RoleBasedGuard>
          },
          {
            path: '/premiados/:sorteoId/:agencia/umns',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']}><GerenciaAgenciasUmn /></RoleBasedGuard>
          },
          {
            path: '/premiados/:sorteoId/:agencia/umns/:umnId',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']}> <PlanillaPremiadosGerenciaUmn /></RoleBasedGuard>
          },
          {
            path: '/premiados/sorteo/:sorteoId',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']}> <PlanillaPremiadosSorteoGerencia /></RoleBasedGuard>
          },
          {
            path: '/premiados/:agencia/:umnId/corredor/:numeroCorredor/sorteo/:sorteo_id',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia']}
            rights={[ 'read']}> <PlanillaPremiadosCorredorGerencia /></RoleBasedGuard>
          },
          {
            path: '/proyecciones',
            element: <RoleBasedGuard  roles={['tdp_gerencia', 'presidencia', 'admin_juego']}
            rights={[ 'read']}> <Proyeccion /></RoleBasedGuard>
          }
        ]
      },
      {
        path: '/mode-holmes',
        element: <RoleBasedGuard roles={['presidencia']}
        rights={[ 'read' ]}> <Sherlock /> </RoleBasedGuard>
      },
      {
        path: '/premiadosPorJugada/:sorteoId',
        element: <RoleBasedGuard roles={['presidencia', 'gerencia']}
        rights={['read']}> <PremiadosGerencia /> </RoleBasedGuard>
      },
      {
        path: '/raspaditaAsignaciones',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
        rights={[ 'read' ]}> <AsignacionesGerencia /> </RoleBasedGuard>
      },
      {
        path: '/stock',
        children: [
          {
            path: '/raspadita-tipo-juego',
            element: <RoleBasedGuard roles={['raspa_operativa']}
            rights={[ 'read' ]}> <PlanillaTotalStockJuego /> </RoleBasedGuard>
          },
          {
            path: '/pallets',
            element: <RoleBasedGuard roles={['raspa_operativa']}
            rights={[ 'read' ]}> <StockPallets /> </RoleBasedGuard>
          },
          {
            path: '/pallets/cajas/:palletCodigo',
            element: <RoleBasedGuard roles={['raspa_operativa']}
              rights={['read']}> <StockCajas /> </RoleBasedGuard>
          },
          {
            path: '/pallets/cajas/:palletCodigo/libros/:cajaCodigo',
            element: <RoleBasedGuard roles={['raspa_operativa']}
              rights={['read']}> <StockLibros /> </RoleBasedGuard>
          },
          {
            path: '/pallets/cajas/:palletCodigo/libros/:cajaCodigo/boletas/:libroCodigo',
            element: <RoleBasedGuard roles={['raspa_operativa']}
              rights={['read']}> <StockBoletas /> </RoleBasedGuard>
          },
          {
            path: '/raspadita-tipo-juego-dias-asignados',
            element: <RoleBasedGuard roles={['raspa_operativa']}
              rights={['read']}> <PlanillaTotalStockJuegoDias /> </RoleBasedGuard>
          },
          {
            path: '/raspadita-tipo-juego/agencias/:tipoJuego',
            element: <RoleBasedGuard roles={['raspa_operativa']}
              rights={['read']}> <StockRaspaditaGerencia /> </RoleBasedGuard>
          },
          {
            path: 'boletasAsignadasCorredoresGerencia/:agenciaNumero/:tipoJuego',
            element: <RoleBasedGuard roles={['raspa_operativa']}
              rights={['read']}> <RaspaditaCorredoresBoletasAsignadasGerencia /> </RoleBasedGuard>
          },
        ]
      },
      {
        path: '/boletasPorMonto',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
        rights={['read']}><BoletasPorMonto /></RoleBasedGuard>
      },
      {
        path: '/historialDeSorteos',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
        rights={['read']}><HistorialDeMontos /></RoleBasedGuard>
      },
      {
        path: '/ocurrenciasTombola',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
        rights={['read']}><TombolaOcurrencia /></RoleBasedGuard>
      },
      {
        path: '/estadoCuenta',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
            rights={['read']}><FinanzasGerencia /></RoleBasedGuard>
          },
          {
            path: '/:nroAgencia/:nombreAgencia',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
            rights={['read']}><FinanzasGerenciaDetalle /></RoleBasedGuard>
          }
        ]
      },
      {
        path: '/ocurrenciasGlobales',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
        rights={['read']}><Ocurrencias /></RoleBasedGuard>
      },
      {
        path: '/bolillero',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
        rights={['read']}><Bolillero /></RoleBasedGuard>
      },
      {
        path: '/estadoJugador',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <InformeCuentaJugador /></RoleBasedGuard>
      },
      {
        path: '/ventaAgencias',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <VentasPorAgencia /></RoleBasedGuard>
      },
      {
        path: '/mediosElectronicos',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <VentasPorMediosElectronicos /></RoleBasedGuard>
      },
      {
        path: '/mediosTerminales',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <VentasPorTerminal /></RoleBasedGuard>
      },
      {
        path: '/reporteSistema',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <ReportesDelSistema /></RoleBasedGuard>
      },
      {
        path: '/reporteAgencia',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <ReporteFacturacionAgencia /></RoleBasedGuard>
      },
      {
        path: '/loginFallidos',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <IntentosLogin /></RoleBasedGuard>
      },
      {
        path: '/parametrosSistema',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <CambiosParametrosSistema /></RoleBasedGuard>
      },
      {
        path: '/uptimeSistema',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <UpdateSistema /></RoleBasedGuard>
      },
      {
        path: '/anulacionesEventos',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <AnulacionesConajzar /></RoleBasedGuard>
      },
      {
        path: '/softwareUpdate',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <UpdateSoftware /></RoleBasedGuard>
      },
      {
        path: '/hearbeatsEventos',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <Heartbeat /></RoleBasedGuard>
      },
      {
        path: '/registroAsistencia',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <RegistroDeAsistencias /></RoleBasedGuard>
      },
      {
        path: '/reportePagoPremios',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <ReportePagoDePremios /></RoleBasedGuard>
      },
      {
        path: '/reportesPagoSuperior',
        element: <RoleBasedGuard  roles={['conajzar']}
        rights={[ 'read']}> <ReportePagoPremiosSuperiores /></RoleBasedGuard>
      },
      {
        path: '/informeLiquidaciones',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <InformeLiquidaciones /> </RoleBasedGuard>
      },
      {
        path: '/facturacion-raspadita',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
        rights={['read']}> <InformeFacturacionRaspadita /> </RoleBasedGuard>
      },
      {
        path: '/facturacion-raspadita-gerencia',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
              rights={['read']}> <InformeFacturacionRaspaditaGerencia /> </RoleBasedGuard>
          },
          {
            path: '/:tipojuego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
            rights={['read']}><VentasPorAgenciaGerencia /></RoleBasedGuard>
          },
          {
            path: '/:tipojuego/agencia/:agencia_numero/',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
            rights={['read']}><VentasPorCorredorAgencia /></RoleBasedGuard>
          }
        ]
      },
      {
        path: '/ganadores',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
        rights={['read']}> <InformePremiadosRaspadita /> </RoleBasedGuard>
      },
      {
        path: '/libros-vendidos',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
        rights={['read']}> <InformeLibrosVendidosRaspadita /> </RoleBasedGuard>
      },
      {
        path: '/ventas-por-corredor',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <PlanillaVentasPorCorredor /> </RoleBasedGuard>
      },
      {
        path: '/ventas-por-corredor/juego/:codigoJuego',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <PlanillaVentasPorCorredorAgencias /> </RoleBasedGuard>
      },
      {
        path: '/ventas-por-corredor/juego/:codigoJuego/agencia/:agenciaNumero',
        element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <PlanillaVentasPorCorredorByCorredor /> </RoleBasedGuard>
      },
      {
        path: '/asignadas-por-corredor',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <PlanillaAsignadasPorCorredor /> </RoleBasedGuard>
      },
      {
        path: '/asignadas-por-corredor/juego/:codigoJuego',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <PlanillaAsignadasPorCorredorAgencias /> </RoleBasedGuard>
      },
      {
        path: '/asignadas-por-corredor/juego/:codigoJuego/agencia/:agenciaNumero',
        element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
          rights={['read']}> <PlanillaAsignadasPorCorredorByCorredor /> </RoleBasedGuard>
      },
      {
        path: '/ganadoresGerencia',
        children: [
          {
            path: '/',
            element: <RoleBasedGuard roles={['conajzar', 'conajzar_raspa', 'presidencia', 'tdp_gerencia', 'raspa_operativa']}
              rights={['read']}> <InformePremiadosRaspaditaGerencia /> </RoleBasedGuard>
          },
          {
            path: '/:tipojuego',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
            rights={['read']}><GanadoresPorAgenciaGerencia /></RoleBasedGuard>
          },
          {
            path: '/:tipojuego/agencia/:agencia_numero/',
            element: <RoleBasedGuard roles={['presidencia', 'tdp_gerencia']}
            rights={['read']}><GanadoresPorCorredorAgencia /></RoleBasedGuard>
          }
        ]
      },
      {
        path: '/accesoRemoto',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <AccesoRemoto /> </RoleBasedGuard>
      },
      {
        path: '/auditoriaFirewall',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <AuditoriaFirewall /> </RoleBasedGuard>
      },
      {
        path: '/apuestasAbortadas',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <AbortoApuestas /> </RoleBasedGuard>
      },
      {
        path: '/cambiosPosibilidades',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <CambiosPosibilidades /> </RoleBasedGuard>
      },
      {
        path: '/erroresIncompatibildad',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <ErrorIncompatibilidad /> </RoleBasedGuard>
      },
      {
        path: '/estacionApuestas',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <EstacionDeApuestas /> </RoleBasedGuard>
      },
      {
        path: '/reporteFacturacionDiaria',
        element: <RoleBasedGuard roles={['conajzar']}
        rights={['read']}> <ReporteFacturacionDiaria /> </RoleBasedGuard>
      }
    ]
  },
  {
    path: '*',  // ex  *
    element: <MainLayout />,
    children: [
             {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
