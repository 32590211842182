import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import { gql, useApolloClient } from "@apollo/client";
import PropTypes from 'prop-types';
import jwt_decode from "jwt-decode";
import axios from '../lib/axios'
import type { User } from '../types/user';

const QUERY = gql`
  query{
    me{
      usuario{
        Umns{
          agencia_numero
          id
          numero
          nombre
          zona
        }
        Cobradores{
        id
        numero
        linea
        cuenta
        Umn{
          id
          numero
          agencia_numero
        }
      }      Corredores{
        id
        numero
        linea
        cuenta
        Umn{
          numero
          agencia_numero
        }
      }

        Permisos{
        Role{
          name
        }
        Rights{
          name
        }
      }
        id
    username
    created_at
    Person{
      nombre
      apellido
    }
      }
  }
  }
`;


interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (accessToken: string, user: User) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;


    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const apolloClient = useApolloClient();



  const getMeData=async ()=>{


        const {data} = await apolloClient.query({
          query: QUERY
        });




        const user:User = {
          avatar:  `https://i.pravatar.cc/300?u=${data.me.usuario.id}`,
          email: 'aa@example.com',
          id: data.me.usuario.id,
          permisos: data.me.usuario.Permisos,
          umns: data.me.usuario.Umns,
          cobradores: data.me.usuario.Cobradores,
          corredores: data.me.usuario.Corredores,
          corredor: data.me.usuario.Corredores?.length>0?data.me.usuario.Corredores[0]:null ,
          cobrador: data.me.usuario.Cobradores?.length>0?data.me.usuario.Cobradores[0]:null ,
          name: `${data.me.usuario.Person.nombre} ${data.me.usuario.Person.apellido}`
        }

        console.log("USER STATE AUTH CONTEXT * * * * ", user);
        localStorage.setItem('UMNid', `${[user?.umns[0]?.id]}`)
        // localStorage.setItem('agencia_numero', user?.umns[0]?.agencia_numero)

        return user;
  }




  const initialize = async (): Promise<void> => {
    try {
      let accessToken = window.localStorage.getItem('accessToken');
      if (accessToken) {
        const decoded = jwt_decode(accessToken);
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const iat = decoded['iat'] * 1000;
        const actualDate = new Date().getTime();
        const tiempoEnMin = (actualDate - iat) / 60000;
        if (tiempoEnMin > 60) {
          // cerramos sesion
          window.localStorage.removeItem('accessToken');
          accessToken = undefined;
        }
      }

      if (accessToken) {
        setSession(accessToken);
        const user = await getMeData()
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };


  useEffect(() => {
    initialize();
  }, []);




  const login = async (accessToken: string, user: User): Promise<void> => {
    // const response = await axios.post<{ accessToken: string; user: User }>('/api/authentication/login', {
    //   email,
    //   password
    // });
    // const { accessToken, user } = response.data;


    console.log("accessToken en jwt context", accessToken, user);
    localStorage.clear()
    setSession(accessToken);


    initialize();

    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user
    //   }
    // });


  };




  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };




  const register = async (
    email: string,
    name: string,
    password: string
  ): Promise<void> => {
    const response = await axios.post<{ accessToken: string; user: User }>(
      '/api/authentication/register',
      {
        email,
        name,
        password
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};






AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
