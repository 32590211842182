import { useEffect, useContext, useState } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Typography,
	// IconButton,
	Divider,
	// FormControl,
	// InputLabel,
	// ListItemIcon,
	// Checkbox,
	// Input,
	// Select,
	// MenuItem,
	// ListItemText,
  // Icon,
} from '@material-ui/core';
import XLSX from 'xlsx'
import dayjs from 'dayjs';
import TableReportesDelSistema from 'src/components/dashboard/conajzar/TableReportesDelSistema'
import ErrorMessage from 'src/components/dashboard/error/ErrorMessage';
import Loading from '../../../components/loading/Loading'
import useSettings from '../../../hooks/useSettings';
import { DateContext, UMNContext,FilterGerenciaContext, AgenciaContext } from '../../../contexts'
import { useReporteDeSistemaQuery } from '../../../generated/graphql'

const ReportesDelSistema: FC = () => {
  const { settings } = useSettings();

  const {actualDate} =  useContext(DateContext)
  const { UMN: umnId } = useContext(UMNContext)
  const { sorteo } = useContext(FilterGerenciaContext)
  const { agencia } = useContext(AgenciaContext)

  const [variables, setVariables] = useState({
    desde: actualDate.startDate,
    hasta: actualDate.endDate,
    agencia,
    sorteo_id: sorteo === '0' || sorteo === 0 ? [2,4,5, 20] : sorteo
  })

	const {data, error, loading} = useReporteDeSistemaQuery({variables, fetchPolicy: 'network-only'})
	const handleSubmit = () => {
    setVariables({
      desde: actualDate.startDate,
      hasta: actualDate.endDate,
      agencia,
      sorteo_id: sorteo === '0' || sorteo === 0 ? [2,4,5, 20] : sorteo
    })
  }
	useEffect(() => {
    handleSubmit()
  }, [umnId, sorteo, actualDate])

  if(loading) return <Loading />
  if(error){
    return <ErrorMessage title={error.name}
      message={error.message} />
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Conajzar | TDPBIZ 2.0</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <Grid
           container
          justifyContent="space-between"
					spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              Reportes del Sistema
            </Typography>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Desde: { dayjs(actualDate?.startDate).format("DD/MM/YYYY") }, Hasta: { dayjs(actualDate?.endDate).format("DD/MM/YYYY") } {/* Aqui va la fecha que recibo formateada  */}
            </Typography>
          </Grid>
          <Grid item>
            {/* <Grid>
            <FormControl sx={{ width:350}}>
            </FormControl>
            <IconButton sx={{maxWidth: "55px", maxHeight: "75px", height: "55px",  marginTop: "10px", marginLeft: "10px", border: "solid 1px #bdbdbd"}}
              type="submit"
              onClick={() => {})}
              aria-label="search">
              <Icon>search</Icon>
            </IconButton>
            </Grid> */}
          </Grid>
        </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
							<Box sx={{ flexGrow: 1 }}>
									<Divider orientation="horizontal"
                  sx={{backgroundColor:"white"}} />
							</Box>
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <TableReportesDelSistema items={ data?.reporteDeSistema }
                  xlsx={XLSX}
                  refrescar={() => {}} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ReportesDelSistema;
