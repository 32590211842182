/* eslint-disable new-cap */
import {  useEffect, useRef } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  // Grid,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  // Link
} from '@material-ui/core';
import { jsPDF } from 'jspdf';
// import html2canvas from 'html2canvas';
import autoTable from "jspdf-autotable";
import dayjs from 'dayjs';
import formatNumbers from 'src/utils/formatNumbers';
import Loading from 'src/components/loading/Loading'
import Actions from '../../ActionsFacturacion';
// import Label from '../../Label';
// import {Link as RouterLink} from 'react-router-dom'
// import formatWithoutDot from '../../../utils/formatWithoutDot'
import Scrollbar from '../../Scrollbar';

interface JuegosListTableProps {
  items: any;
  xlsx?: any;
  refrescar?: any;
}
interface JuegosListTableProps {
  items: any;
  xlsx?: any;
  refrescar?: any;
}

const TableInformeFacturacionRaspadita: FC<JuegosListTableProps> = (props) => {
  const { items, xlsx, refrescar, ...other } = props;
  useEffect(() => {}, [items])
  const inputRef = useRef(null);

  const totales = {
    boletas: 0,
    monto: 0,
  }

  const printDocument = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Facturacion";
    const headers = [["Fecha", "Agencia Numero", "Boletas", "Juego", "Monto Total"]];

    let data = items.map((item)=> [dayjs(item?.fecha).format("DD-MM-YYYY"), item?.agencia_numero, item?.total_boletas, item?.tipo_juego, item?.total_monto_vendido.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")]);
    data = [...data, ["Total: ", "", formatNumbers(totales.boletas), "", formatNumbers(totales.monto)]]
    const content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    autoTable(doc, content)
    doc.save(`facturacion_${dayjs().format("DD-MM-YYYY")}.pdf`);
  }

  return (
    <>
      <Card {...other}
        id="divToPrint"
        ref={inputRef}>

          <CardHeader
          action={<Actions xlsx={xlsx}
          items={items}
          printDocument={printDocument}/>}
          title="Reporte de raspaditas vendidas"
        />
        <Divider />
        <Scrollbar>
          <Box sx={{ minWidth: 1150 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                <TableCell align="center">
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Fecha
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Agencia Numero
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Total Boletas
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Tipo Juego
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Monto Total
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items === null ? <TableRow
                      hover
                      key={0}
                    >
                      <TableCell />
                      <TableCell />
                      <TableCell >
                        <Loading />
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow> : null}
                {items?.map((item) => {
                  totales.boletas += parseInt(item?.total_boletas, 10);
                  totales.monto += parseInt(item?.total_monto_vendido, 10);
                  return (
                    <TableRow
                      hover
                      key={item?.id}
                    >
                      <TableCell align="center">
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                        >
                          {dayjs(item?.fecha).format("DD-MM-YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                        >
                          {item?.agencia_numero}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                        >
                          {item?.total_boletas}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                        >
                          {item?.tipo_juego}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          color="textPrimary"
                          variant="subtitle1"
                        >
                          {item?.total_monto_vendido.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                }
                )}
                {items !== null ? 
                <TableRow hover>
                  <TableCell align="center">
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell align="center">
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {formatNumbers(totales.boletas)}
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell align="center">
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {formatNumbers(totales.monto)}
                    </Typography>
                  </TableCell>
                </TableRow> : null}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </Card>
    </>
  );
};

TableInformeFacturacionRaspadita.propTypes = {
  items: PropTypes.any,
  xlsx: PropTypes.any,
  refrescar: PropTypes.any
};

export default TableInformeFacturacionRaspadita;
