import type { FC } from 'react';
import { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { DateContext } from 'src/contexts/DateContext';
import "../../../css/estiloGerencia.css"
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import dayjs from "dayjs"
import MoreMenu from '../../MoreMenu';
import Scrollbar from '../../Scrollbar';
import ArrowRight from '../../../icons/ArrowRight';
import formatNumbers from '../../../utils/formatNumbers';
import AlertOpenBoxes from '../../gerencia/AlertOpenBoxes'
import { FilterGerenciaContext } from '../../../contexts/FilterContext'

interface TableRecaudacionAgenciaProps {
  items: any[];
  idProducto?: any;
  RouterLink?: any;
  umnoption?: any
}

const TableRecaudacionAgencia: FC<TableRecaudacionAgenciaProps> = (props) => {
    const { items, idProducto, umnoption, ...other } = props;
    const totales = {
        cantidad: 0,
        bruto: 0,
        premio: 0,
        percent: 0,
        comisionA: 0,
        comisionC: 0,
        totalPremiados: 0,
        neto: 0,
        cajas: 0
    }

    const {actualDate} = useContext(DateContext)

    useEffect(() => {}, [items])

    const { sorteo } = useContext(FilterGerenciaContext)

    const selectLink = (umnop, item) => {
        if (umnop) {
            return `/gerencia/premiados/${sorteo}/${item.agencia_numero}/umns/${item.umn_id}`;
        }
        return parseInt(item.cantidad_umn, 10) > 1 ?
        `/gerencia/premiados/${sorteo}/${item.agencia_numero}/umns`:
        `/gerencia/premiados/${sorteo}/${item.agencia_numero}`
    }
    return (
    <>
        <Card {...other}>
            <CardHeader
                action={<MoreMenu />}
                title="Recaudacion por Agencia"
            />
						 <Typography
                color="textSecondary"
                variant="h6"
                sx = {{marginLeft: 2}}
            >
                Desde {dayjs(actualDate.startDate).format("DD/MM/YYYY")} hasta {dayjs(actualDate.endDate).format("DD/MM/YYYY")}
            </Typography>
            <Divider />
            <Scrollbar>
                <Box sx={{ minWidth: 720 }}>
                    <Table stickyHeader
                    className="tableColor"
                    style={{ height: 150 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    {umnoption ? `SubAgencia`: `Agencia`}
																	</Typography>
                                </TableCell >
                                <TableCell />
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Cantidad
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premiados
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Total Bruto
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Premio
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    %
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comisión Agencia
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Comisión Corredor
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Neto
																	</Typography>
                                </TableCell>
                                <TableCell align="right">
																<Typography
																		color="textPrimary"
																		variant="h6"
																	>
                                    Opciones
																	</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => {
                                totales.cantidad += parseInt(item.cantidad, 10)
                                totales.bruto += parseInt(item.monto, 10)
                                totales.premio += parseInt(item.premio, 10)
                                totales.comisionA += parseInt(item.comisionA, 10)
                                totales.comisionC += parseInt(item.comisionC, 10)
                                totales.totalPremiados += parseInt(item.cantidad_premiados, 10)
                                totales.neto += (parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10))
                                totales.cajas += parseInt(item?.cajas_abiertas, 10)
                                console.log("Item: ",item)
                                return <TableRow
                                key={item.id}
                                >
                                    <TableCell align="left">
                                        <Typography
                                          variant="subtitle1"
                                        >
                                            {umnoption ? `AG ${item.nombre} Umn ${item.umn_id}` : `AG ${item.agencia_numero} ${item.nombre}`}
                                        </Typography>
                                    </TableCell>
                                    {item?.cajas_abiertas > 0 ?
                                      <TableCell sx={{maxWidth: '300px'}}>
                                        <AlertOpenBoxes quantity={parseInt(item?.cajas_abiertas, 10)} />
                                      </TableCell>
                                      :
                                      <TableCell />
                                    }
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
																				  { formatNumbers(parseInt(item.cantidad, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography

                                            variant="subtitle1"
                                        >
                                          { formatNumbers(parseInt(item.cantidad_premiados, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                          variant="subtitle1"
                                        >
                                          { `${formatNumbers(parseInt(item.monto, 10))}` }
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                          variant="subtitle1"
                                        >
                                          { item.premio? formatNumbers(parseInt(item.premio, 10)): "-" }
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography
                                            color={parseInt(item.premio, 10) > parseInt(item.monto, 10) * 0.54? "red": "textPrimary"}
                                            sx={{fontSize: parseInt(item.premio, 10) > parseInt(item.monto, 10) * 0.54? "20px": "16px"}}
                                            variant="subtitle1"
                                        >
                                            {((parseInt(item.premio, 10) / parseInt(item.monto, 10)) * 100).toFixed(2)}%
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { formatNumbers(parseInt(item.comisionA, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { formatNumbers(parseInt(item.comisionC, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                        >
                                            { parseInt(item.premio, 10) ? formatNumbers(parseInt(item.monto, 10) - parseInt(item.premio, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10)): formatNumbers(parseInt(item.monto, 10) - parseInt(item.comisionA, 10) - parseInt(item.comisionC, 10))}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      <Grid
                                        display='flex'
                                        direction='row'
                                        justifyContent="flex-end"
                                      >
                                        <Button
                                          component={RouterLink}
                                          to={
                                              selectLink(umnoption, item)
                                            }
                                          sx={{maxHeight:10}}
                                        >
                                          <IconButton>
                                          <ArrowRight
                                              fontSize="small"
                                              style={{color: "#D1236C"}}
                                          />
                                          </IconButton>
                                        </Button>
                                      </Grid>
                                    </TableCell>
                                </TableRow>
                                }
                            )}
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        variant="h6"
                                    >
                                        Total
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                  {totales.cajas > 0
                                    ?
                                      <AlertOpenBoxes quantity={totales.cajas} />
                                    :
                                    null
                                  }
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
									                      color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.cantidad)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
									                      color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.totalPremiados)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
                                        color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.bruto)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color='textPrimary'
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.premio)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography
                                        color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {((totales.premio / totales.bruto) * 100).toFixed(2)}%
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.comisionA)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.comisionC)}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align="right"
                                >
                                    <Typography
										                    color="textPrimary"
                                        sx={{fontWeight:"bold"}}
                                    >
                                        {formatNumbers(totales.neto)}
                                    </Typography>
                                </TableCell>
																<TableCell
                                    align="right"
                                />
                            </TableRow>
                        </TableBody>
                </Table>
            </Box>
            </Scrollbar>
        </Card>
    </>)
};

TableRecaudacionAgencia.propTypes = {
  items: PropTypes.array.isRequired,
  idProducto: PropTypes.any,
  umnoption: PropTypes.any
};

export default TableRecaudacionAgencia;
