import type { FC } from 'react'
import {useContext, useState, useEffect} from 'react'
// import { useContext, useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  Divider
} from '@material-ui/core';
import XLSX from 'xlsx'
import TableRecaudacionAgenciaGerencia from 'src/components/dashboard/gestionar/TableRecaudacionAgenciaGerencia';
import TableRecaudacionAgenciaGerenciaAgrupado from 'src/components/dashboard/gestionar/TableRecaudacionAgenciaGerenciaAgrupado';
import { useParams, Link as RouterLink } from 'react-router-dom';
// import Loading from 'src/components/loading/Loading'
// import {UMNContext} from '../../contexts/UMNContext'
// settings
import Loading from 'src/components/loading/Loading';
import { useAgenciaProductoGestionarGerenciaQuery } from 'src/generated/graphql';
import { FilterGerenciaContext } from 'src/contexts';
import { DateContext } from '../../../../contexts/DateContext'
import useSettings from '../../../../hooks/useSettings';
import ChevronRightIcon from '../../../../icons/ChevronRight';

const PlanillaGeneralAgencia: FC = () => {
  // const { actualDate } = useContext(DateContext)
  // const { UMN } = useContext(UMNContext)
  // const umnId = UMN

  const { settings } = useSettings();

  const { idProducto, juegoId } = useParams()
  console.log("asdjasdkjhasldhadlskjhasdklhkls",idProducto, juegoId)
  const { actualDate } = useContext(DateContext)
  const { agencia } = useContext(FilterGerenciaContext)
  const [agrupar] = useState(false)

  let varAgencia = [parseInt(agencia, 10)];
  const varJuegoId = parseInt(juegoId, 10);

  const [variables, setVariables] = useState({
    producto_id: idProducto, desde: actualDate.startDate, hasta: actualDate.endDate, agencia_numero: agencia, agrupar, juego_id: varJuegoId
  })
  const { data: {resumenUmnProductoGestionarGerencia} = {}, error, loading } = useAgenciaProductoGestionarGerenciaQuery({variables, fetchPolicy: 'network-only'})

  useEffect(() => {
    if (agencia === 0 || agencia === '0') {
      varAgencia = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 99, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 112, 113,  114, 115, 116, 117, 118];
    }
    setVariables({
      ...variables,
      agencia_numero: varAgencia
    })
  }, [agencia])


  if (loading) {
		return <Loading />
	}

  if (error) {
    return <h1>Ha ocurrido un error</h1>
  }


  return (
    <>
      <Helmet>
          <title>Dashboard: Detalles por producto Agencias | TDPBIZ 2.0</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <Grid
           container
          justifyContent="space-between"
					spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              Planilla de Agencias por Producto
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<ChevronRightIcon fontSize="small" />}
            sx={{ mt: 1 }}
            maxItems={4}
          >
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/"
              variant="subtitle2"
            >
              Planilla General
            </Link>
            <Typography
              color="textSecondary"
              variant="subtitle2"
            >
              Agencias Producto
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Box sx={{ mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
							<Box sx={{ flexGrow: 1 }}>
									<Divider orientation="horizontal"
                    sx={{backgroundColor:"white"}}
                  />
							</Box>
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                {agrupar ? <TableRecaudacionAgenciaGerenciaAgrupado items={resumenUmnProductoGestionarGerencia?.TransaccionesAgrupadas || []}
xlsx={XLSX} /> :<TableRecaudacionAgenciaGerencia xlsx={XLSX}
items={resumenUmnProductoGestionarGerencia?.TransaccionesAgrupadas || []}
idProducto={idProducto} />}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default PlanillaGeneralAgencia;
