import  { createContext, useState } from 'react'
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types'


interface FilterGerenciaProps {
    children?: ReactNode;
}

export const FilterGerenciaContext = createContext<any>({
    agencia: 0,
    corredor: null,
    sorteo: "0",
    setSorteo: () => {},
    setCorredor: () => {},
    setAgencia: () => {},
})

export const FilterGerenciaProvider:FC<FilterGerenciaProps> = ({ children }) => {
    const [agencia, setAgencia] = useState(0)
    const [corredor, setCorredor] = useState(null)
    const [sorteo, setSorteo] = useState("0")
    const value = {
          agencia,
          corredor,
          sorteo,
          setAgencia,
          setCorredor,
          setSorteo,
        }
    return (
      <FilterGerenciaContext.Provider value={value}>
        {children}
      </FilterGerenciaContext.Provider>
    )
  }


export const FilterGerenciaConsumer = FilterGerenciaContext.Consumer


FilterGerenciaProvider.propTypes = {
    children: PropTypes.node.isRequired
};