import { useState, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import Actions from '../../ActionsAgrupado';
import Scrollbar from '../../Scrollbar';
import formatNumbers from '../../../utils/formatNumbers';

interface PremiadosListI {
  items: any;
  xlsx: any;
}

const applyPagination = (
  items: any,
  page: number,
  limit: number
): any[] => items
  .slice(page * limit, page * limit + limit);

const RecaudacionAgenciaGerenciaAgrupado: FC<PremiadosListI> = (props) => {
  const { items, xlsx, ...other } = props;

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const paginateditems = applyPagination(items, page, limit);
  const totales = {
    cantidad: 0,
    bruto: 0,
    premio: 0,
    percent: 0,
    comisionA: 0,
    comisionC: 0,
    totalPremiados: 0,
    neto: 0
  }

  useEffect(() => {}, [items]);

  return (
    <>
      <Card {...other}>
        <CardHeader
          action={<Actions xlsx={xlsx}
          items={items}/>}
          title="Corredor "
        />
        <Divider />
        <Scrollbar>
          <Box sx={{ minWidth: 1150 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Agencia
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Cantidad
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Premiados
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Total Bruto
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Premio
                    </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      %
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Comision Agencia
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Comision Corredor
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Neto
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginateditems?.map((item) =>  {
                  totales.cantidad += parseInt(item.cantidad, 10)
                  totales.bruto += parseInt(item.total, 10)
                  totales.premio += parseInt(item.premio, 10)
                  totales.comisionA += parseInt(item.totalComisionA, 10)
                  totales.comisionC += parseInt(item.totalComisionC, 10)
                  totales.totalPremiados += parseInt(item.cantidad_premiados, 10)
                  totales.neto += (parseInt(item.total, 10) - parseInt(item.premio, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))
                  return <TableRow
                  key={item.id}
                  >
                      <TableCell align="left">
                          <Typography
                            variant="subtitle1"
                          >
                              {`AG ${item.agencia_numero}`}
                          </Typography>
                      </TableCell>
                      <TableCell
                          align="right"
                      >
                          <Typography

                              variant="subtitle1"
                          >
                                { formatNumbers(item.cantidad) }
                          </Typography>
                      </TableCell>
                      <TableCell
                          align="right"
                      >
                          <Typography

                              variant="subtitle1"
                          >
                            { formatNumbers(item.cantidad_premiados)}
                          </Typography>
                      </TableCell>
                      <TableCell
                          align="right"
                      >
                          <Typography

                              variant="subtitle1"
                          >
                              { `${formatNumbers(item.total)}` }
                          </Typography>
                      </TableCell>
                      <TableCell
                          align="right"
                      >
                          <Typography

                              variant="subtitle1"
                          >
                              { item.premio? formatNumbers(item.premio): "-" }
                          </Typography>
                      </TableCell>
                      <TableCell align="right">
                          <Typography
                              color={item.premio > item.monto * 0.54? "red": "textPrimary"}
                              sx={{fontSize: item.premio > item.monto * 0.54? "20px": "16px"}}
                              variant="subtitle1"
                          >
                              {((parseInt(item.premio, 10) / parseInt(item.total, 10)) * 100).toFixed(2)}%
                          </Typography>
                      </TableCell>
                      <TableCell
                          align="right"
                      >
                          <Typography
                              variant="subtitle1"
                          >
                              { formatNumbers(parseInt(item.totalComisionA, 10))}
                          </Typography>
                      </TableCell>
                      <TableCell
                          align="right"
                      >
                          <Typography
                              variant="subtitle1"
                          >
                              { formatNumbers(parseInt(item.totalComisionC, 10))}
                          </Typography>
                      </TableCell>
                      <TableCell
                          align="right"
                      >
                          <Typography
                              variant="subtitle1"
                          >
                              { item.premio ? formatNumbers((parseInt(item.total, 10) - parseInt(item.premio, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10))): formatNumbers((parseInt(item.total, 10) - parseInt(item.totalComisionA, 10) - parseInt(item.totalComisionC, 10)))}
                          </Typography>
                      </TableCell>
                  </TableRow>
                })}
                 <TableRow>
                    <TableCell>
                        <Typography
                            variant="h6"
                        >
                            Total
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <Typography
                                                color='textPrimary'
                            sx={{fontWeight:"bold"}}
                        >
                            {formatNumbers(totales.cantidad)}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <Typography
                                                color='textPrimary'
                            sx={{fontWeight:"bold"}}
                        >
                            {formatNumbers(totales.totalPremiados)}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <Typography
                            color='textPrimary'
                            sx={{fontWeight:"bold"}}
                        >
                            {formatNumbers(totales.bruto)}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <Typography
                                                color='textPrimary'
                            sx={{fontWeight:"bold"}}
                        >
                            {formatNumbers(totales.premio)}
                        </Typography>
                    </TableCell>
                    <TableCell align="right">
                        <Typography
                            color="textPrimary"
                            sx={{fontWeight:"bold"}}
                        >
                            {((totales.premio / totales.bruto) * 100).toFixed(2)}%
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <Typography
                                                color="textPrimary"
                            sx={{fontWeight:"bold"}}
                        >
                            {formatNumbers(totales.comisionA)}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <Typography
                                                color="textPrimary"
                            sx={{fontWeight:"bold"}}
                        >
                            {formatNumbers(totales.comisionC)}
                        </Typography>
                    </TableCell>
                    <TableCell
                        align="right"
                    >
                        <Typography
                                                color="textPrimary"
                            sx={{fontWeight:"bold"}}
                        >
                            {formatNumbers(totales.neto)}
                        </Typography>
                    </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={items.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[50, 100, 250]}
        />
      </Card>
    </>
  );
};

RecaudacionAgenciaGerenciaAgrupado.propTypes = {
  items: PropTypes.any,
  xlsx: PropTypes.any
};

export default RecaudacionAgenciaGerenciaAgrupado;