import { useEffect, useContext, useState } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Grid,
  Typography,
  // IconButton,
  Divider,
  // FormControl,
  // InputLabel,
  // ListItemIcon,
  // Checkbox,
  TextField
  // Input,
  // Select,
  // MenuItem,
  // ListItemText,
  // Icon,
} from '@material-ui/core';
import XLSX from 'xlsx'
import dayjs from 'dayjs';
import TableInformeFacturacionRaspadita from 'src/components/dashboard/conajzar/TableInformeFacturacionRaspadita'
import ErrorMessage from 'src/components/dashboard/error/ErrorMessage';
import Loading from 'src/components/loading/Loading'
import useSettings from 'src/hooks/useSettings';
import { DateContext, UMNContext } from 'src/contexts'
import { useSorteosQuery } from 'src/generated/graphql'
import axios from 'axios';

const InformeFacturacionRaspadita: FC = () => {
  const { settings } = useSettings();
  const [tipoJuego, setTipoJuego] = useState(null);
  const [datosFacturacion, setDatosFacturacion] = useState(null);
  const token = localStorage.getItem('accessToken');
  const { actualDate } = useContext(DateContext)
  const { UMN: umnId } = useContext(UMNContext)
  const [error, setError] = useState(null);
  const [value, setValue] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const getTipoJuego = async () => {
    setError('')
    setLoadingData(true)
    await axios.get(`https://fasttdpbiz.tedepasa.com/api/v1/stock/juegos`, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        console.log(response)
        let data: any[] = response.data as any[];
        data.push({
          codigo: "0000",
          created_at: "2021-12-20T21:14:25.488Z",
          deleted_at: null,
          id: 0,
          tipo: "Todas",
          updated_at: "2021-12-20T21:14:25.488Z"
        })
        data = data.sort((a, b) => a.codigo - b.codigo);
        setTipoJuego(data);
      })
      .catch((response) => {
        setError(response)
      })
      .finally(() => {
        setLoadingData(false)
      });
  }

  const getDatosFacturacion = async (tipo_juego_id) => {
    setError('')
    setLoadingData(true)
    setDatosFacturacion(null);
    let umnids = [];
    console.log('umnId: ', umnId)
    if (umnId?.length === undefined) umnids.push(umnId);
    if (umnId?.length !== undefined) umnids = umnId;
    await axios.post(`https://apiraspaditav2prod.tedepasa.com/api/v2/tickets/total-vendido`, {
      "juego_id": Number(tipo_juego_id),
      "desde": actualDate?.startDate,
      "hasta": actualDate?.endDate,
      "umn_ids": umnids
    }, { headers: { "Authorization": `Bearer ${token}` } })
      .then((response) => {
        setDatosFacturacion(response.data);
      })
      .catch((response) => {
        setError(response)
      }).finally(() => {
        setLoadingData(false)
      })
  }

  const { loading } = useSorteosQuery({ fetchPolicy: 'network-only' })

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (umnId) {
        getTipoJuego()
        getDatosFacturacion(0)
      }
    }
    console.log("value", value)
    if (value) getDatosFacturacion(value);
    mounted = false
  }, [umnId, actualDate, value])

  const handleChange = (event) => {
    const tipo_juego_id = event?.target?.value;
    console.log(tipo_juego_id !== "Seleccione")
    if (tipo_juego_id !== "Seleccione") getDatosFacturacion(tipo_juego_id);
    if (tipo_juego_id !== "Seleccione") setValue(tipo_juego_id);
  };


  if (loading) return <Loading />
  if (loadingData) return <Loading />
  if (error) {
    return <ErrorMessage title={error.name}
      message={error.message} />
  }

  return (
    <>
      <Helmet>
        <title>Dashboard: Conajzar | TDPBIZ 2.0</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Facturación
              </Typography>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Desde: {dayjs(actualDate?.startDate).format("DD/MM/YYYY")}, Hasta: {dayjs(actualDate?.endDate).format("DD/MM/YYYY")} {/* Aqui va la fecha que recibo formateada  */}
              </Typography>
              <TextField
                sx={{ width: 175, marginBottom: 1, marginLeft: 1, marginTop: 1 }}
                label="Tipo Juego"
                select
                SelectProps={{ native: true }}
                variant="outlined"
                name="tipo-juego"
                onChange={handleChange}
                value={value}
              ><option
                key={3}
              >
                  Seleccione
                </option>
                {tipoJuego.map((option) => (
                  <option
                    key={option?.id}
                    value={option?.id}
                  >
                    {option?.tipo}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Divider orientation="horizontal"
                  sx={{ backgroundColor: "white" }} />
              </Box>
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <TableInformeFacturacionRaspadita items={datosFacturacion}
                  xlsx={XLSX}
                  refrescar={() => { }} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default InformeFacturacionRaspadita;
