import type { FC } from 'react';
import { Grid, Alert, AlertTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

interface ErrorMessageI {
  title: string;
  message: string;
};

const ErrorMessage: FC<ErrorMessageI> = ({title, message}) => (
  <Grid
      sx={{
          backgroundColor: 'transparent',
          minHeight: '100%',
          minWidth: '100%',
          display: 'flex'
      }}
      alignItems='center'
      justifyContent='center'
  >
    <Alert severity="error">
      <AlertTitle>{title}</AlertTitle>
      {message} — <strong>!</strong>
    </Alert>
  </Grid>
);

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
}

export default ErrorMessage;
